import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SectionPeriod, AppConfig, DataEvent } from 'src/app/entities';
import { HttpClient } from '@angular/common/http';
import { Uris } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  /**
   * Source utilisée pour les listes de périodes
   */
  private _periodsSource = new Subject<SectionPeriod[]>();

  /**
   * Source utilisée pour la configuration
   */
  private _configSource = new Subject<AppConfig>();

  /**
   * Observable lancé à chaque réception d'une liste de périodes
   */
  public periods$ = this._periodsSource.asObservable();

  /**
   * Observable lancé à chaque réception d'une configuration
   */
  public config$ = this._configSource.asObservable();

  constructor(private _http: HttpClient) { }

  /**
   * Demande la récupération des périodes
   */
  public getPeriods() {
    this._http.get<SectionPeriod[]>(Uris.PERIODS)
      .pipe(
        map(periods => {
          _.each(periods, (period, i) => {
            period.id = i;
          });
          return periods.map(p => new SectionPeriod().deserialize(p));
        })
      )
      .subscribe(periods => this._periodsSource.next(periods));
  }

  /**
   * Demande la récupération de la configuration
   */
  public getConfig() {
    this._http.get<AppConfig>(Uris.CONFIG)
      .pipe(
        map(c => {
          let events = c.events.map(e => new DataEvent().deserialize(e));
          let finalEvents: DataEvent[][] = [];

          let maxDepthEvent = _.maxBy(events, 'rank');

          for (let i = 0; i <= maxDepthEvent.rank; i++) { // les ranks démarrent à 0
            finalEvents.push([]);
          }
          
          _.each(events, event => {
            finalEvents[event.rank].push(event);
          });

          _.each(finalEvents, (stageEvents, i) => {
            finalEvents[i] = _.sortBy(stageEvents, ['start', 'end']);
          })
          c.events = finalEvents;
          return c;
        }),
        map(c => new AppConfig().deserialize(c)),
        tap((c: AppConfig) => {
          _.each(c.events, event => {
            this._fixStartEnd(event);
          })
        })
      )
      .subscribe(config => this._configSource.next(config));
  }

  private _fixStartEnd(event: DataEvent) {
    if (event.events) {
      _.each(event.events, e => {
        this._fixStartEnd(e);
        if (event.start > e.start) {
          event.start = e.start;
        }
        if (event.end < e.end) {
          event.end = e.end;
        }
      });
    }
  }

  /**
   * Trie la liste des événements par date de début/fin
   * @param events Liste des événements
   */
  private _sortEvents(events: DataEvent[]): DataEvent[] {
    events = _.sortBy(events, ['start', 'end']);
    _.each(events, (event: DataEvent) => {
      if (event.events) {
        event.events = this._sortEvents(event.events);
      }
    })

    return events;
  }
}
