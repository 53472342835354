import * as tslib_1 from "tslib";
import { EntityMapper } from './entity-mapper.model';
/**
 * Événement géologique
 */
var DataEvent = /** @class */ (function (_super) {
    tslib_1.__extends(DataEvent, _super);
    function DataEvent() {
        var _this = _super.call(this) || this;
        /**
         * ID de l'événement
         */
        _this.id = null;
        /**
         * ID de l'événement parent
         */
        _this.parentId = 0;
        /**
         * Rang de l'événement
         */
        _this.rank = 0;
        /**
         * Nom de l'événement
         */
        _this.title = "";
        /**
         * Détails de l'événement
         */
        _this.details = "";
        /**
         * Début de l'événement (en Ma)
         */
        _this.start = null;
        /**
         * Fin de l'événement (en Ma)
         */
        _this.end = null;
        /**
         * Type d'événement
         */
        _this.type = "";
        /**
         * Sous-événements
         */
        _this.events = null;
        /**
         * Style visuel de l'élément
         */
        _this.style = null;
        /**
         * Durée de l'élément (en Ma)
         */
        _this.duration = -1;
        /**
         * L'élément est-il affiché à sa durée réelle ?
         */
        _this.isDisplayReal = true;
        /**
         * Faut-il afficher le nom dans un tooltip ?
         */
        _this.needTooltip = false;
        /**
         * Commentaires de l'événement
         */
        _this.comments = [];
        /**
         * Afficher les commentaires ?
         */
        _this.displayComments = false;
        _this._mapperDefs = [
            { front: 'id', back: 'idevt' },
            { front: 'parentId', back: 'evtidpar' },
            { front: 'rank', back: 'evtrankid' },
            { front: 'events', class: DataEvent },
            { front: 'title', back: 'evtname' },
            { front: 'start', back: 'StartBaseBoundaryValue' },
            { front: 'end', back: 'EndBaseBoundaryValue' },
            { front: 'type', back: 'evttype' }
        ];
        return _this;
    }
    DataEvent.prototype.deserialize = function (json) {
        _super.prototype.deserialize.call(this, json);
        this.id = Number(this.id);
        this.parentId = Number(this.parentId);
        this.rank = Number(this.rank);
        if (this.start > 0) {
            this.start = Number(this.start) * -1;
        }
        if (this.end > 0) {
            this.end = Number(this.end) * -1;
        }
        this.duration = Math.round((this.end - this.start) * 1000) / 1000; // trick anti-problème de précision des flottants en JS
        return this;
    };
    return DataEvent;
}(EntityMapper));
export { DataEvent };
