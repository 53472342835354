<div id="sheet-tree" class="d-flex flex-column h-100">
  <div class="sheet-tab-title d-flex align-items-center">
    <h2>Arbre événementiel</h2>
    <div class="type-filter-container d-flex align-items-center ml-auto">
      <label for="sheet-tree-filter" class="mb-1 mr-2">Filtrer par type :</label>
      <div class="type-filter">
        <p-multiSelect [options]="types" [(ngModel)]="selectedTypes" inputId="sheet-tree-filter" styleClass="w-100"
          class="w-100" [filter]="false" defaultLabel="Aucun type sélectionné"
          selectedItemsLabel="{0} types sélectionnés" [maxSelectedLabels]="2" (onChange)="filterEventsByType()">
        </p-multiSelect>
      </div>
    </div>
  </div>
  <div id="d3-container" class="sheet-tab-content">
    <svg></svg>
  </div>
</div>