import { EntityMapper } from './entity-mapper.model';
import { DataEvent } from './data-event.model';

/**
 * Document d'événement
 */
export class SheetDocument extends EntityMapper {
  /**
   * URL vers le document
   */
  url: string = "";

  /**
   * Nom
   */
  title: string = "";

  /**
   * ID de l'événement lié
   */
  eventId: number = null;

  /**
   * Événement lié
   */
  event: DataEvent = null;

  /**
   * Date de création du document
   */
  date: Date = null;

  /**
   * Type de document ('image', 'pdf', 'xls' ou 'doc')
   */
  type: string = "";

  constructor() {
    super();

    this._mapperDefs = [
      { front: 'date', class: Date }
    ];
  }
}