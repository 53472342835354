import * as tslib_1 from "tslib";
import { EntityMapper } from './entity-mapper.model';
/**
 * Document d'événement
 */
var SheetDocument = /** @class */ (function (_super) {
    tslib_1.__extends(SheetDocument, _super);
    function SheetDocument() {
        var _this = _super.call(this) || this;
        /**
         * URL vers le document
         */
        _this.url = "";
        /**
         * Nom
         */
        _this.title = "";
        /**
         * ID de l'événement lié
         */
        _this.eventId = null;
        /**
         * Événement lié
         */
        _this.event = null;
        /**
         * Date de création du document
         */
        _this.date = null;
        /**
         * Type de document ('image', 'pdf', 'xls' ou 'doc')
         */
        _this.type = "";
        _this._mapperDefs = [
            { front: 'date', class: Date }
        ];
        return _this;
    }
    return SheetDocument;
}(EntityMapper));
export { SheetDocument };
