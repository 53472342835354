import * as d3 from 'd3';
import * as _ from 'lodash';
import * as $ from 'jquery';
import { AfterViewInit, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { DataEvent } from 'src/app/entities';
import { Subscription } from 'rxjs';
var SheetTreeComponent = /** @class */ (function () {
    function SheetTreeComponent(_utils, _sheetService) {
        this._utils = _utils;
        this._sheetService = _sheetService;
        /**
         * Liste des types d'événements pour les filtres
         */
        this.types = [];
        /**
         * Liste des types d'événements sélectionnés dans le filtre
         */
        this.selectedTypes = [];
        /**
         * Envoyé quand on souhaite afficher un événement
         */
        this.onDisplayEvent = new EventEmitter();
        /**
         * Taille d'affichage du texte (en px)
         */
        this._fontSize = 14;
        /**
         * Hauteur de ligne du texte (multiplicateur de la font size)
         */
        this._lineHeight = 1.3;
        /**
         * Marge interne des nodes pour le placement du texte
         */
        this._nodePadding = this._fontSize * 0.36;
        /**
         * Taille standard d'un node (le height sera surchargé en fonction du texte)
         */
        this._nodeSize = {
            width: this._fontSize * 12.15 + (this._nodePadding * 2),
            height: (this._fontSize * this._lineHeight) + (this._nodePadding * 2)
        };
        /**
         * Marge minimale entre les nodes
         */
        this._nodeMargin = {
            x: 10,
            y: 60
        };
        /**
         * Compteur de ligne par étage de l'arborescence
         */
        this._textLinesByStage = [];
        /**
         * Contient toutes les souscriptions du composant
         */
        this._subs = new Subscription();
    }
    SheetTreeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subs.add(this._sheetService.filterTypes$.subscribe(function (types) { return _this._filterEvents(types); }));
        var types = this._sheetService.getEventTypeList(this.events);
        types = _.sortBy(types, function (t) { return _this._utils.removeAccents(t); });
        this.selectedTypes = types;
        this.types = [];
        _.each(types, function (type) {
            _this.types.push({ label: type, value: type });
        });
    };
    SheetTreeComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    SheetTreeComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () { return _this._generateTree(_this._generateDataTree()); }, 500);
    };
    /**
     * Filtre les événements affichés en fonction des types sélectionnés
     */
    SheetTreeComponent.prototype.filterEventsByType = function () {
        this._sheetService.filterEventsByTypes(this.selectedTypes);
    };
    /**
     * Effectue le filtrage des événements
     * @param types Types sur lesquels filtrer
     */
    SheetTreeComponent.prototype._filterEvents = function (types) {
        this.selectedTypes = types;
        var svg = d3.select("svg");
        svg.selectAll(".arbo-link")
            .attr("opacity", function (d) { return types.indexOf(d.data.type) < 0 ? 0.2 : 1; });
        svg.selectAll(".arbo-node")
            .attr("opacity", function (d) { return types.indexOf(d.data.type) < 0 ? 0.2 : 1; })
            .attr("cursor", function (d) { return types.indexOf(d.data.type) < 0 ? "default" : "pointer"; });
    };
    /**
     * Génère l'arborescence de données pour d3js
     */
    SheetTreeComponent.prototype._generateDataTree = function () {
        var _this = this;
        var flatEvents = _.flatten(this.events);
        var topEvent = _.find(flatEvents, { parentId: 0 });
        var data = null;
        if (topEvent) {
            data = {
                id: topEvent.id,
                name: this._setTextToMultiline(topEvent.title),
                type: topEvent.type
            };
            var events = [topEvent];
            this._textLinesByStage.push(data.name.length);
            var currentDataList_1 = [data];
            var stage_1 = 1;
            var _loop_1 = function () {
                var foundChilds = [];
                var dataList = [];
                this_1._textLinesByStage.push(0);
                _.each(events, function (event, i) {
                    var eventChilds = _.filter(flatEvents, { parentId: event.id });
                    if (!currentDataList_1[i].children && eventChilds.length > 0) {
                        currentDataList_1[i].children = [];
                    }
                    _.each(eventChilds, function (c) {
                        var data = {
                            id: c.id,
                            name: _this._setTextToMultiline(c.title),
                            type: c.type
                        };
                        currentDataList_1[i].children.push(data);
                        dataList.push(data);
                        if (_this._textLinesByStage[stage_1] < data.name.length) {
                            _this._textLinesByStage[stage_1] = data.name.length;
                        }
                    });
                    foundChilds = foundChilds.concat(eventChilds);
                });
                currentDataList_1 = dataList;
                events = foundChilds;
                stage_1++;
            };
            var this_1 = this;
            do {
                _loop_1();
            } while (events.length > 0);
        }
        return data;
    };
    /**
     * Génère l'arborescence SVG des événements
     * @param eventTree Données des événements pour l'arborescence
     */
    SheetTreeComponent.prototype._generateTree = function (eventTree) {
        this._root = d3.hierarchy(eventTree, function (d) { return d.children; });
        this._root.dx = this._nodeSize.width + (this._nodeMargin.x * 2);
        this._root.dy = this._nodeSize.height + (this._nodeMargin.y * 2);
        this._treeMap = d3.tree().nodeSize([this._root.dx, this._root.dy]);
        var svg = d3.select("#d3-container svg")
            .attr('width', "100%")
            .attr('height', "100%")
            .attr("viewBox", [0, 0, $('#d3-container').width(), $('#d3-container').height()]);
        var g = svg.append("g")
            .attr("font-family", "Arial,sans-serif")
            .attr("font-size", this._fontSize);
        // Bind des événements de zoom et translation
        this._zoomListener = d3.zoom()
            .scaleExtent([0.1, 3])
            .on("zoom", function () { return g.attr("transform", function () { return d3.event.transform; }); });
        svg.call(this._zoomListener);
        this._updateTree();
    };
    /**
     * Dessine le lien entre deux nodes
     * @param d Informations des deux nodes
     */
    SheetTreeComponent.prototype._drawLink = function (d) {
        var startPoint = [d.parent.x, d.parent.y];
        var endPoint = [d.x, d.y - this._getNodeHeight(d)];
        var q1Point = [startPoint[0], (startPoint[1] + endPoint[1]) / 2];
        var q2Point = [endPoint[0], (startPoint[1] + endPoint[1]) / 2];
        var path = 'M' + startPoint[0] + ' ' + startPoint[1];
        path += 'C' + q1Point[0] + ' ' + q1Point[1] + ',';
        path += q2Point[0] + ' ' + q2Point[1] + ',';
        path += endPoint[0] + ' ' + endPoint[1];
        return path;
    };
    /**
     * Génère un tableau multiligne du texte en fonction de sa taille et de la largeur d'une node
     * @param text Texte à transformer
     */
    SheetTreeComponent.prototype._setTextToMultiline = function (text) {
        var textWidth = this._utils.measureText(text, this._fontSize);
        if (textWidth <= (this._nodeSize.width - (this._nodePadding * 2))) {
            return [text];
        }
        var currentLine = "";
        var split = text.split(' ');
        var lines = [];
        for (var i = 0; i < split.length; i++) {
            if (!currentLine || this._utils.measureText(currentLine + ' ' + split[i], this._fontSize) <= (this._nodeSize.width - (this._nodePadding * 2))) {
                if (currentLine) {
                    currentLine += ' ';
                }
                currentLine += split[i];
            }
            else {
                lines.push(currentLine);
                currentLine = split[i];
            }
        }
        lines.push(currentLine);
        return lines;
    };
    /**
     * Renvoie la hauteur de la node en fonction de sa quantité de texte
     * @param node Node
     */
    SheetTreeComponent.prototype._getNodeHeight = function (node) {
        return (this._textLinesByStage[node.depth] * this._fontSize * this._lineHeight) + (this._nodePadding * 2);
    };
    /**
     * Lance le repli/dépli d'une node
     * @param d Node
     */
    SheetTreeComponent.prototype._toggleNodeChildren = function (d) {
        if (d.children) {
            d._children = d.children;
            d.children = null;
        }
        else if (d._children) {
            d.children = d._children;
            d._children = null;
        }
        this._updateTree(d);
    };
    /**
     * Demande de voir l'événement
     * @param d Node
     */
    SheetTreeComponent.prototype._goToEvent = function (d) {
        if (this.selectedTypes.indexOf(d.data.type) >= 0) {
            this.onDisplayEvent.emit(d.data.id);
        }
    };
    /**
     * Met à jour l'arborescence avec animations
     * @param source Node repliée/dépliée
     */
    SheetTreeComponent.prototype._updateTree = function (source) {
        var _this = this;
        // Définition x et y
        var treeData = this._treeMap(this._root);
        var duration = 750;
        var nodes = treeData.descendants();
        var links = treeData.descendants().slice(1);
        if (!source) {
            source = _.find(nodes, function (n) { return n.data.id === _this.baseEventId; });
            source.x0 = $('#d3-container').width() / 2;
            source.y0 = 0;
        }
        // customs de taille par rapport aux tailles de texte
        var diffs = [];
        _.each(this._textLinesByStage, function (stageLinesNumber, i) {
            var realheight = (stageLinesNumber * _this._fontSize * _this._lineHeight) + (_this._nodePadding * 2);
            var diff = realheight - _this._nodeSize.height;
            if (i > 0) {
                diff += diffs[i - 1];
            }
            diffs.push(diff);
        });
        _.each(nodes, function (node) {
            node.y += diffs[node.depth];
        });
        // ======= MAJ des nodes =======
        var arboNodes = d3.select("#d3-container svg g")
            .selectAll("g.arbo-node")
            .data(nodes, function (d) { return d.data.id; });
        // Placement des nodes entrantes (nouvelles données/uncollapse les enfants)
        var arboNodesEnter = arboNodes.enter().append("g")
            .attr("class", "arbo-node")
            .attr("opacity", 0)
            .attr("transform", function (d) { return "translate(" + source.x0 + "," + source.y0 + ") scale(0)"; });
        // Dessin de la node
        var nodeSquare = arboNodesEnter.append("g")
            .attr("class", "arbo-node-square")
            .on('click', function (d) { return _this._goToEvent(d); });
        nodeSquare.append("rect")
            .attr("fill", "#005a74")
            .attr("width", this._nodeSize.width)
            .attr("height", function (d) { return _this._getNodeHeight(d); });
        nodeSquare.append("text")
            .attr("dy", 0)
            .attr("x", 0)
            .attr("fill", "white")
            .attr("text-anchor", "middle")
            .selectAll("tspan")
            .data(function (d) {
            var names = [];
            _.each(d.data.name, function (namePart) {
                names.push({
                    depth: d.depth,
                    name: namePart
                });
            });
            return names;
        })
            .enter()
            .append("tspan")
            .attr("dy", function (d, i, datas) {
            if (i === 0) {
                var textheight = datas.length * _this._fontSize * _this._lineHeight;
                return ((_this._getNodeHeight(d) - textheight) / 2) + _this._fontSize;
            }
            return (_this._fontSize * _this._lineHeight);
        })
            .attr("x", (this._nodeSize.width / 2))
            .text(function (d) { return d.name; });
        // Bouton de collapse de la node
        var collapseBtn = arboNodesEnter.filter(function (d) { return d.children || d._children; }).append("g")
            .attr("class", "arbo-node-collapse-btn")
            .attr("transform", function (d) { return "translate(" + (_this._nodeSize.width / 2) + "," + (_this._getNodeHeight(d) + 8) + ")"; })
            .on('click', function (d) { return _this._toggleNodeChildren(d); });
        collapseBtn.append("circle")
            .attr('r', 11)
            .attr("fill", "#dc3545");
        collapseBtn.append("text")
            .attr("x", 0)
            .attr("fill", "white")
            .attr("font-size", "1.2em")
            .attr("text-anchor", "middle");
        // Ajout des nodes entrantes dans les nodes actuelles
        var arboNodesUpdate = arboNodesEnter.merge(arboNodes);
        arboNodesUpdate.selectAll(".arbo-node-collapse-btn text")
            .attr("dy", function (d) { return d.children ? 4 : 6; })
            .text(function (d) { return d.children ? "-" : "+"; });
        // Mise à jour animée des nodes entrantes et actuelles
        arboNodesUpdate
            .transition()
            .duration(duration)
            .attr("opacity", function (d) { return _this.selectedTypes.indexOf(d.data.type) < 0 ? 0.2 : 1; })
            .attr("cursor", function (d) { return _this.selectedTypes.indexOf(d.data.type) < 0 ? "default" : "pointer"; })
            .attr("transform", function (d) { return "translate(" + (d.x - (_this._nodeSize.width / 2)) + "," + (d.y - _this._getNodeHeight(d)) + ") scale(1)"; });
        // Animation puis suppression des nodes sortantes (collapsed)
        arboNodes.exit()
            .transition()
            .duration(duration)
            .attr("opacity", 0)
            .attr("transform", function (d) { return "translate(" + source.x + "," + source.y + ") scale(0)"; })
            .remove();
        // ======= MAJ des liens =======
        var nodesLinks = d3.select("#d3-container svg g")
            .selectAll("path.arbo-link")
            .data(links, function (d) { return d.data.id; });
        // Placement des liens des nodes entrantes
        var nodesLinksEnter = nodesLinks.enter().insert('path', 'g')
            .attr("class", "arbo-link")
            .attr("fill", "none")
            .attr("stroke", "#888")
            .attr("stroke-opacity", 1)
            .attr("stroke-width", 1.5)
            .attr("d", function (d) { return _this._drawLink({
            x: source.x0,
            y: source.y0 + _this._getNodeHeight(d),
            depth: d.depth,
            parent: { x: source.x0, y: source.y0 }
        }); })
            .attr("opacity", 0);
        // Ajout des liens des nodes entrantes aux liens actuels, puis animation
        nodesLinksEnter.merge(nodesLinks)
            .transition()
            .duration(duration)
            .attr("d", function (d) { return _this._drawLink(d); })
            .attr("opacity", function (d) { return _this.selectedTypes.indexOf(d.data.type) < 0 ? 0.2 : 1; });
        // Animation puis suppression des liens des nodes sortantes
        nodesLinks.exit()
            .transition()
            .duration(duration)
            .attr("d", function (d) { return _this._drawLink({
            x: source.x,
            y: source.y + _this._getNodeHeight(d),
            depth: d.depth,
            parent: { x: source.x, y: source.y }
        }); })
            .attr("opacity", 0)
            .remove();
        // Mise à jour des positions des nodes
        _.each(nodes, function (node) {
            node.x0 = node.x;
            node.y0 = node.y;
        });
        // Déplacement vers la node repliée/dépliée
        var g = d3.select("#d3-container svg g");
        var transform = d3.zoomTransform(g.node());
        transform.x = (-source.x * transform.k) + $('#d3-container').width() / 2;
        transform.y = (-source.y * transform.k) + $('#d3-container').height() / 2;
        g.transition()
            .duration(duration)
            .attr("transform", transform);
        setTimeout(function () { return d3.select("#d3-container svg").call(_this._zoomListener.transform, transform); }, duration);
    };
    return SheetTreeComponent;
}());
export { SheetTreeComponent };
