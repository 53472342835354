import { EntityMapper } from './entity-mapper.model';

/**
 * Période de fond de la frise
 */
export class SectionPeriod extends EntityMapper {
  /**
   * Identifiant de la période
   */
  id: number = null;

  /**
   * Nom de la période
   */
  title: string = "";

  /**
   * Début de la période (en Ma)
   */
  start: number = null;

  /**
   * Fin de la période (en Ma)
   */
  end: number = null;

  /**
   * Couleur de la période (tiré de http://infoterre.brgm-rec.fr/fiche-registry/thesaurus.php?ncl=chrono)
   */
  color: string = "#666666";

  /**
   * Ère de la période (en anglais)
   */
  era: string = "";

  /**
   * Style inline de la période
   */
  style: any = {};

  /**
   * Dégradé de couleur pour la limite
   */
  gradient: string = "";
}
