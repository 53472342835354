import { EntityMapper } from './entity-mapper.model';
import { SheetComment } from './sheet-comment.model';

/**
 * Événement géologique
 */
export class DataEvent extends EntityMapper {
  /**
   * ID de l'événement
   */
  id: number = null;

  /**
   * ID de l'événement parent
   */
  parentId: number = 0;

  /**
   * Rang de l'événement
   */
  rank: number = 0;

  /**
   * Nom de l'événement
   */
  title: string = "";

  /**
   * Détails de l'événement
   */
  details: string = "";

  /**
   * Début de l'événement (en Ma)
   */
  start: number = null;

  /**
   * Fin de l'événement (en Ma)
   */
  end: number = null;

  /**
   * Type d'événement
   */
  type: string = "";

  /**
   * Sous-événements
   */
  events?: DataEvent[] = null;

  /**
   * Style visuel de l'élément
   */
  style: any = null;

  /**
   * Durée de l'élément (en Ma)
   */
  duration: number = -1;

  /**
   * L'élément est-il affiché à sa durée réelle ?
   */
  isDisplayReal: boolean = true;

  /**
   * Faut-il afficher le nom dans un tooltip ?
   */
  needTooltip: boolean = false;

  /**
   * Commentaires de l'événement
   */
  comments: SheetComment[] = [];

  /**
   * Afficher les commentaires ?
   */
  displayComments: boolean = false;

  constructor() {
    super();

    this._mapperDefs = [
      { front: 'id', back: 'idevt' },
      { front: 'parentId', back: 'evtidpar' },
      { front: 'rank', back: 'evtrankid' },
      { front: 'events', class: DataEvent },
      { front: 'title', back: 'evtname' },
      { front: 'start', back: 'StartBaseBoundaryValue' },
      { front: 'end', back: 'EndBaseBoundaryValue' },
      { front: 'type', back: 'evttype' }
    ];
  }

  public deserialize(json: any): this {
    super.deserialize(json);

    this.id = Number(this.id);
    this.parentId = Number(this.parentId);
    this.rank = Number(this.rank);
    if(this.start > 0) {
      this.start = Number(this.start) * -1;
    }
    if(this.end > 0) {
      this.end = Number(this.end) * -1;
    }

    this.duration = Math.round((this.end - this.start) * 1000) / 1000; // trick anti-problème de précision des flottants en JS

    return this;
  }
}