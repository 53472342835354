import * as _ from 'lodash';
import * as $ from 'jquery';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var NavigationService = /** @class */ (function () {
    function NavigationService() {
        /**
         * Source de demande de navigation
         */
        this._navigationSource = new Subject();
        /**
         * Lancé à chaque de demande de navigation
         */
        this.navigation$ = this._navigationSource.asObservable();
        /**
         * Source de demande de mise à jour de la map de la frise
         */
        this._updateMapSource = new Subject();
        /**
         * Lancé à chaque demande de mise à jour de la map de la frise
         */
        this.updateMap$ = this._updateMapSource.asObservable();
    }
    /**
     * Demande une navigation
     * @param destination Position X de destination, en pixels
     * @param animationTime (optionnel) Temps d'animation de la navigation, en ms (0 par défaut)
     */
    NavigationService.prototype.navigateTo = function (destination, animationTime) {
        this._navigationSource.next({
            destination: destination,
            animationTime: animationTime || 0
        });
    };
    /**
     * Demande une navigation vers une période précise
     * @param idx Index de la période ou indication de son voisinage ('next' ou 'previous')
     * @param animationTime (optionnel) Temps d'animation de la navigation, en ms (0 par défaut)
     */
    NavigationService.prototype.navigateToSection = function (idx, animationTime) {
        if (_.isString(idx)) {
            if (idx === 'next') {
                idx = this.getCurrentSectionIndex() + 1;
            }
            else if (idx === 'previous') {
                idx = this.getCurrentSectionIndex() - 1;
            }
        }
        if (idx < 0) {
            idx = 0;
        }
        if (_.isFinite(idx) && idx >= 0 && idx < $('#timeline-sections li').length) {
            var $section = $('#timeline-sections li').eq(idx);
            if ($section.length > 0) {
                this.updateMap(animationTime || 300, idx);
                this.navigateTo($section.offset().left + $('#main').scrollLeft() - 55, animationTime || 300);
            }
        }
    };
    /**
     * Demande la mise à jour de la map de la frise
     * @param animationTime (optionnel) Temps d'animation de la navigation, en ms (0 par défaut)
     * @param targetSectionIdx (optionnel) Index de la période ciblée
     */
    NavigationService.prototype.updateMap = function (animationTime, targetSectionIdx) {
        this._updateMapSource.next({
            animationTime: animationTime || 0,
            targetSectionIdx: _.isFinite(targetSectionIdx) ? targetSectionIdx : null
        });
    };
    /**
     * Renvoie l'index de la section dans laquelle se trouve actuellement le côté gauche de l'écran
     */
    NavigationService.prototype.getCurrentSectionIndex = function () {
        var index = null;
        var position = $('#main').scrollLeft();
        $('#timeline-sections li').each(function (i) {
            var itemPosition = $(this).offset().left + position - 60;
            if (position >= itemPosition && position < (itemPosition + $(this).width())) {
                index = i;
                return false;
            }
        });
        return index;
    };
    NavigationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavigationService_Factory() { return new NavigationService(); }, token: NavigationService, providedIn: "root" });
    return NavigationService;
}());
export { NavigationService };
