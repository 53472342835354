import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { Subject, Observable, of, throwError, forkJoin } from 'rxjs';
import { Sheet, SheetComment, DataEvent, SheetDocument, SheetBibliography } from 'src/app/entities';
import { HttpClient } from '@angular/common/http';
import { Uris } from '../constants';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SheetsService {
  /**
   * Source de la feuille d'informations
   */
  private _sheetSource = new Subject<Sheet>();

  /**
   * Source du filtrage par type d'événement
   */
  private _filterTypesSource = new Subject<string[]>();

  /**
   * Lancé à chaque récupération d'une feuille d'informations
   */
  public sheet$ = this._sheetSource.asObservable();

  /**
   * Lancé à chaque filtrage par type d'événement
   */
  public filterTypes$ = this._filterTypesSource.asObservable();

  constructor(
    private _http: HttpClient
  ) { }

  /**
   * Renvoie la liste étagée des événements faisant partie de la hiérarchie de l'événement
   * @param event Événement dont extraire la hiérarchie
   * @param events Liste étagée des événéments
   */
  public getEventHierarchy(event: DataEvent, events: DataEvent[][]): DataEvent[][] {
    let finalEvents: DataEvent[][] = [];
    for (let i = 0; i < events.length; i++) {
      finalEvents.push([]);
    }
    let hierarchyEvents: DataEvent[] = [event];

    let flatEvents = _.flatten(events);

    let foundEvent = event;
    do {
      foundEvent = _.find(flatEvents, { id: foundEvent.parentId })
      if (foundEvent) {
        hierarchyEvents.push(foundEvent);
      }
    } while (foundEvent && foundEvent.parentId);

    let childs: DataEvent[] = [event];

    do {
      let foundChilds = [];
      _.each(childs, (child: DataEvent) => {
        foundChilds = foundChilds.concat(_.filter(flatEvents, { parentId: child.id }));
      });
      childs = foundChilds;
      hierarchyEvents = hierarchyEvents.concat(childs);
    } while (childs.length > 0);

    _.each(finalEvents, (e, stage) => {
      finalEvents[stage] = _.filter(hierarchyEvents, { rank: stage });
    });

    return finalEvents;
  }

  /**
   * Recherche un événement par id dans une liste étagée
   * @param eventId ID de l'événement à trouver
   * @param events Liste étagée des événements
   */
  public findEvent(eventId: number, events: DataEvent[][]): DataEvent {
    let event: DataEvent;
    for (let stage = 0; stage < events.length; stage++) {
      event = _.find(events[stage], { id: eventId });
      if (event) {
        return event;
      }
    }
    return null;
  }

  /**
   * Génère la feuille d'informations de l'événement
   * @param event Liste contenant l'événement et ses parents
   */
  public generateSheet(event: DataEvent, events: DataEvent[][]) {
    // génération des parents et de l'élément actuel
    let sheet = new Sheet();

    sheet.title = event.title;
    sheet.events = events;

    const eventIds = [];
    _.each(sheet.events, (events: DataEvent[]) => {
      _.each(events, (event: DataEvent) => {
        eventIds.push(event.id);
      });
    });

    let calls: Observable<any[]>[] = [];
    calls.push(this._http.get<any[]>(Uris.INFOS));
    calls.push(this._http.post<any[]>(`${Uris.COMMENTS}search`, eventIds));
    calls.push(this._http.get<any[]>(Uris.DOCUMENTS));
    calls.push(this._http.get<any[]>(Uris.BIBLIOS));

    forkJoin(calls)
      .pipe(
        map(results => {
          let result = {
            infos: results[0],
            comments: results[1].map(c => new SheetComment().deserialize(c)),
            documents: results[2].map(c => new SheetDocument().deserialize(c)),
            biblios: results[3].map(c => new SheetBibliography().deserialize(c))
          };
          return result;
        })
      )
      .subscribe(result => {
        sheet.comments = [];
        sheet.documents = [];
        sheet.bibliography = [];
        _.each(sheet.events, (events: DataEvent[]) => {
          _.each(events, (event: DataEvent) => {
            // Informations
            let descrObj = _.find(result.infos, { eventId: event.id });
            event.details = descrObj ? descrObj.details : "Aucun détail trouvé pour cet événement.";

            // Commentaires
            let comments: SheetComment[] = _.filter(result.comments, { eventId: event.id });
            _.each(comments, (comment: SheetComment) => {
              comment.event = event;
              sheet.comments.push(comment);
            });
            event.comments = _.orderBy(comments, ['date'], ['desc']);

            // Documents
            let documents: SheetDocument[] = _.filter(result.documents, { eventId: event.id });
            _.each(documents, (document: SheetDocument) => {
              document.event = event;
              sheet.documents.push(document);
            });

            // Biblio
            let biblios: SheetBibliography[] = _.filter(result.biblios, { eventId: event.id });
            _.each(biblios, (biblio: SheetBibliography) => {
              biblio.event = event;
              sheet.bibliography.push(biblio);
            });
          });
        });

        sheet.comments = _.orderBy(sheet.comments, ['date'], ['desc']);
        sheet.documents = _.orderBy(sheet.documents, ['date', 'title'], ['desc', 'asc']);
        sheet.bibliography = _.orderBy(sheet.bibliography, ['title'], ['asc']);

        this._sheetSource.next(sheet);
      }, error => {
        console.error(error);
      });
  }

  /**
   * Enregistre un nouveau commentaire
   * @param newComment Nouveau commentaire
   */
  public saveComment(newComment: SheetComment): Observable<any> {
    if (newComment.id) {
      // Modification
      return this._http.put<SheetComment>(Uris.COMMENTS, newComment.serialize());
    } else {
      // Création
      return this._http.post<SheetComment>(Uris.COMMENTS, newComment.serialize());
    }
  }

  /**
   * Récupère la liste des types d'événements
   * @param events Liste étagée des événements où chercher les types
   */
  public getEventTypeList(events: DataEvent[][]): string[] {
    let types: string[] = [];

    _.each(events, (stageEvents: DataEvent[]) => {
      _.each(stageEvents, (event: DataEvent) => {
        if (types.indexOf(event.type) < 0) {
          types.push(event.type);
        }
      });
    });

    return types;
  }

  /**
   * Demande le filtrage par type d'événement
   * @param types Liste des types pour le filtrage
   */
  public filterEventsByTypes(types: string[]) {
    this._filterTypesSource.next(types);
  }
}