import * as $ from 'jquery';

import { Directive, HostListener, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[navigationEvents]'
})
export class NavigationEventsDirective {
  private _isMouseDown: boolean = false;

  @Output() onNavigationEvent = new EventEmitter();

  constructor() { }

  @HostListener('mousewheel', ['$event'])
  public onMouseWheel(event) {
    let delta = event.deltaY || (event.wheelDelta * -1);
    this.onNavigationEvent.emit({ type: 'mousewheel', delta: delta, positionX: event.x, positionY: event.y });
  }

  @HostListener('DOMMouseScroll', ['$event'])
  public onMouseWheelFirefox(event) {
    let direction = (event.detail / Math.abs(event.detail));
    this.onNavigationEvent.emit({ type: 'mousewheel', delta: 100 * direction, positionX: event.x, positionY: event.y });
  }

  @HostListener('window:keyup', ['$event'])
  public onKeyUp(event) {
    // droite, bas => vers la droite
    if ([39].indexOf(event.keyCode) >= 0) {
      this.onNavigationEvent.emit({ type: 'right' });
    }
    // gauche, haut => vers la gauche
    if ([37].indexOf(event.keyCode) >= 0) {
      this.onNavigationEvent.emit({ type: 'left' });
    }
  }

  @HostListener('touchstart', ['$event'])
  public onTouchStart(event) {
    if (event.touches.length === 1) {
      this.onNavigationEvent.emit({ type: 'touchstart', positionX: event.touches[0].pageX, positionY: event.touches[0].pageY });
    }
  }

  @HostListener('touchmove', ['$event'])
  public onTouchMove(event) {
    if (event.touches.length === 1) {
      this.onNavigationEvent.emit({ type: 'touchmove', positionX: event.touches[0].pageX, positionY: event.touches[0].pageY });
    }
  }

  @HostListener('mousedown', ['$event'])
  public onMouseDown(event) {
    this._isMouseDown = true;
    this.onNavigationEvent.emit({ type: 'touchstart', positionX: event.pageX, positionY: event.pageY });
  }

  @HostListener('mouseup')
  public onMouseUp() {
    this._isMouseDown = false;
  }

  @HostListener('mousemove', ['$event'])
  public onMouseMove(event) {
    if (this._isMouseDown) {
      this.onNavigationEvent.emit({ type: 'touchmove', positionX: event.pageX, positionY: event.pageY });
    }
  }

  @HostListener('mouseleave')
  public onMouseLeave() {
    this.onMouseUp();
  }

}
