import * as _ from 'lodash';
import { Subject, Observable, forkJoin } from 'rxjs';
import { Sheet, SheetComment, SheetDocument, SheetBibliography } from 'src/app/entities';
import { Uris } from '../constants';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SheetsService = /** @class */ (function () {
    function SheetsService(_http) {
        this._http = _http;
        /**
         * Source de la feuille d'informations
         */
        this._sheetSource = new Subject();
        /**
         * Source du filtrage par type d'événement
         */
        this._filterTypesSource = new Subject();
        /**
         * Lancé à chaque récupération d'une feuille d'informations
         */
        this.sheet$ = this._sheetSource.asObservable();
        /**
         * Lancé à chaque filtrage par type d'événement
         */
        this.filterTypes$ = this._filterTypesSource.asObservable();
    }
    /**
     * Renvoie la liste étagée des événements faisant partie de la hiérarchie de l'événement
     * @param event Événement dont extraire la hiérarchie
     * @param events Liste étagée des événéments
     */
    SheetsService.prototype.getEventHierarchy = function (event, events) {
        var finalEvents = [];
        for (var i = 0; i < events.length; i++) {
            finalEvents.push([]);
        }
        var hierarchyEvents = [event];
        var flatEvents = _.flatten(events);
        var foundEvent = event;
        do {
            foundEvent = _.find(flatEvents, { id: foundEvent.parentId });
            if (foundEvent) {
                hierarchyEvents.push(foundEvent);
            }
        } while (foundEvent && foundEvent.parentId);
        var childs = [event];
        var _loop_1 = function () {
            var foundChilds = [];
            _.each(childs, function (child) {
                foundChilds = foundChilds.concat(_.filter(flatEvents, { parentId: child.id }));
            });
            childs = foundChilds;
            hierarchyEvents = hierarchyEvents.concat(childs);
        };
        do {
            _loop_1();
        } while (childs.length > 0);
        _.each(finalEvents, function (e, stage) {
            finalEvents[stage] = _.filter(hierarchyEvents, { rank: stage });
        });
        return finalEvents;
    };
    /**
     * Recherche un événement par id dans une liste étagée
     * @param eventId ID de l'événement à trouver
     * @param events Liste étagée des événements
     */
    SheetsService.prototype.findEvent = function (eventId, events) {
        var event;
        for (var stage = 0; stage < events.length; stage++) {
            event = _.find(events[stage], { id: eventId });
            if (event) {
                return event;
            }
        }
        return null;
    };
    /**
     * Génère la feuille d'informations de l'événement
     * @param event Liste contenant l'événement et ses parents
     */
    SheetsService.prototype.generateSheet = function (event, events) {
        var _this = this;
        // génération des parents et de l'élément actuel
        var sheet = new Sheet();
        sheet.title = event.title;
        sheet.events = events;
        var eventIds = [];
        _.each(sheet.events, function (events) {
            _.each(events, function (event) {
                eventIds.push(event.id);
            });
        });
        var calls = [];
        calls.push(this._http.get(Uris.INFOS));
        calls.push(this._http.post(Uris.COMMENTS + "search", eventIds));
        calls.push(this._http.get(Uris.DOCUMENTS));
        calls.push(this._http.get(Uris.BIBLIOS));
        forkJoin(calls)
            .pipe(map(function (results) {
            var result = {
                infos: results[0],
                comments: results[1].map(function (c) { return new SheetComment().deserialize(c); }),
                documents: results[2].map(function (c) { return new SheetDocument().deserialize(c); }),
                biblios: results[3].map(function (c) { return new SheetBibliography().deserialize(c); })
            };
            return result;
        }))
            .subscribe(function (result) {
            sheet.comments = [];
            sheet.documents = [];
            sheet.bibliography = [];
            _.each(sheet.events, function (events) {
                _.each(events, function (event) {
                    // Informations
                    var descrObj = _.find(result.infos, { eventId: event.id });
                    event.details = descrObj ? descrObj.details : "Aucun détail trouvé pour cet événement.";
                    // Commentaires
                    var comments = _.filter(result.comments, { eventId: event.id });
                    _.each(comments, function (comment) {
                        comment.event = event;
                        sheet.comments.push(comment);
                    });
                    event.comments = _.orderBy(comments, ['date'], ['desc']);
                    // Documents
                    var documents = _.filter(result.documents, { eventId: event.id });
                    _.each(documents, function (document) {
                        document.event = event;
                        sheet.documents.push(document);
                    });
                    // Biblio
                    var biblios = _.filter(result.biblios, { eventId: event.id });
                    _.each(biblios, function (biblio) {
                        biblio.event = event;
                        sheet.bibliography.push(biblio);
                    });
                });
            });
            sheet.comments = _.orderBy(sheet.comments, ['date'], ['desc']);
            sheet.documents = _.orderBy(sheet.documents, ['date', 'title'], ['desc', 'asc']);
            sheet.bibliography = _.orderBy(sheet.bibliography, ['title'], ['asc']);
            _this._sheetSource.next(sheet);
        }, function (error) {
            console.error(error);
        });
    };
    /**
     * Enregistre un nouveau commentaire
     * @param newComment Nouveau commentaire
     */
    SheetsService.prototype.saveComment = function (newComment) {
        if (newComment.id) {
            // Modification
            return this._http.put(Uris.COMMENTS, newComment.serialize());
        }
        else {
            // Création
            return this._http.post(Uris.COMMENTS, newComment.serialize());
        }
    };
    /**
     * Récupère la liste des types d'événements
     * @param events Liste étagée des événements où chercher les types
     */
    SheetsService.prototype.getEventTypeList = function (events) {
        var types = [];
        _.each(events, function (stageEvents) {
            _.each(stageEvents, function (event) {
                if (types.indexOf(event.type) < 0) {
                    types.push(event.type);
                }
            });
        });
        return types;
    };
    /**
     * Demande le filtrage par type d'événement
     * @param types Liste des types pour le filtrage
     */
    SheetsService.prototype.filterEventsByTypes = function (types) {
        this._filterTypesSource.next(types);
    };
    SheetsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SheetsService_Factory() { return new SheetsService(i0.ɵɵinject(i1.HttpClient)); }, token: SheetsService, providedIn: "root" });
    return SheetsService;
}());
export { SheetsService };
