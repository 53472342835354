import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Sheet, SheetComment, DataEvent } from 'src/app/entities';
import { SheetsService } from 'src/app/shared/services';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';

@Component({
  templateUrl: './sheet-modal.component.html'
})
export class SheetModalComponent implements OnInit, OnDestroy {
  /**
   * Liste des base contenant l'événement à afficher et ses parents
   */
  @Input() baseEvent: DataEvent;

  /**
   * Liste des base contenant l'événement à afficher et ses parents
   */
  @Input() events: DataEvent[][];

  /**
   * Couleur correspondant à la période à laquelle débute l'événement
   */
  @Input() mainColor: string = '#333';

  /**
   * Fiche de l'événement
   */
  public sheet: Sheet;

  /**
   * Événement à mettre en valeur
   */
  public targetEvent: DataEvent;

  /**
   * Objet NgTabs de Bootstrap
   */
  @ViewChild('tabs', { static: false }) tabs: NgbTabset;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs = new Subscription();

  constructor(
    public modal: NgbActiveModal,
    private _sheetService: SheetsService,
    private _toastr: ToastrService
  ) { }

  ngOnInit() {
    this._subs.add(this._sheetService.sheet$.subscribe(sheet => this._displaySheet(sheet)));

    this._sheetService.generateSheet(this.baseEvent, this.events);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Enregistre un commentaire pour un événement et met à jour la liste des commentaires
   * @param comment Commentaire à enregistrer
   */
  public saveComment(comment: SheetComment) {
    this._sheetService.saveComment(comment)
      .pipe(
        map(e => new SheetComment().deserialize(e))
      )
      .subscribe((deserializedComment) => {
        let event;
        for (let i = 0; i < this.sheet.events.length; i++) {
          event = _.find(this.sheet.events[i], { id: deserializedComment.eventId });
          if (event) {
            break;
          }
        }

        if (event) {
          // Gestion du commentaire existant (suite à modification)
          let existingCommentIndex = _.findIndex(event.comments, { id: deserializedComment.id });
          if (existingCommentIndex > -1) {
            event.comments[existingCommentIndex] = deserializedComment;
          } else {
            event.comments.unshift(deserializedComment);
          }
          event.comments = _.orderBy(event.comments, 'date', 'desc');
          event.displayComments = true;
          deserializedComment.event = event
        }

        // Gestion du commentaire existant (suite à modification)
        let existingCommentIndex = _.findIndex(this.sheet.comments, { id: deserializedComment.id });
        if (existingCommentIndex > - 1) {
          this.sheet.comments[existingCommentIndex] = deserializedComment;
        } else {
          this.sheet.comments.unshift(deserializedComment);
        }
        this.sheet.comments = _.orderBy(this.sheet.comments, 'date', 'desc');
        this._toastr.success("Votre commentaire a été enregistré avec succès");
      }, error => {
        console.error(error);
        this._toastr.error("Une erreur s'est produite pendant l'enregistrement de votre commentaire. Veuillez réessayer plus tard");
      });
  }

  public goToEvent(eventId: number) {
    this.tabs.select('details');
    this.targetEvent = this._sheetService.findEvent(eventId, this.events);
  }

  /**
   * Affiche la fiche de l'événement
   * @param sheet Fiche de l'événement
   */
  private _displaySheet(sheet: Sheet) {
    this.sheet = sheet;
  }

}
