/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./navigation.component";
import * as i3 from "../../../shared/services/configuration.service";
import * as i4 from "../../../shared/services/navigation.service";
var styles_NavigationComponent = [];
var RenderType_NavigationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NavigationComponent, data: {} });
export { RenderType_NavigationComponent as RenderType_NavigationComponent };
function View_NavigationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["periodSection", 1]], null, 5, "li", [["class", "col"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "a", [["title", "Aller \u00E0 cette p\u00E9riode"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPeriod(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(4, { background: 0, color: 1 }), (_l()(), i0.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, _v.context.$implicit.style.background, _v.context.$implicit.style.color); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.title; _ck(_v, 5, 0, currVal_1); }); }
export function View_NavigationComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { periodSections: 1 }), (_l()(), i0.ɵeld(1, 0, null, null, 4, "nav", [["id", "timeline-map"], ["role", "navigation"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "position-marker"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "ul", [["class", "map-list row no-gutter"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavigationComponent_1)), i0.ɵdid(5, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 7, "nav", [["id", "timeline-arrows"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 6, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "a", [["class", "arrow arrow-left"], ["data-goto", "previous"], ["title", "P\u00E9riode pr\u00E9c\u00E9dente"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToNearbyPeriod("previous") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "i", [["class", "fa fa-chevron-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "a", [["class", "arrow arrow-right"], ["data-goto", "next"], ["title", "P\u00E9riode suivante"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToNearbyPeriod("next") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 0, "i", [["class", "fa fa-chevron-right"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.periods; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_NavigationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-navigation", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onWindowResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_NavigationComponent_0, RenderType_NavigationComponent)), i0.ɵdid(1, 4440064, null, 0, i2.NavigationComponent, [i3.ConfigurationService, i4.NavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavigationComponentNgFactory = i0.ɵccf("app-navigation", i2.NavigationComponent, View_NavigationComponent_Host_0, {}, {}, []);
export { NavigationComponentNgFactory as NavigationComponentNgFactory };
