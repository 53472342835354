import { EntityMapper } from './entity-mapper.model';
import { SheetDocument } from './sheet-document.model';
import { SheetComment } from './sheet-comment.model';
import { SheetBibliography } from './sheet-bibliography.model';
import { DataEvent } from './data-event.model';

/**
 * Fiche d'information d'un événement
 */
export class Sheet extends EntityMapper {
  /**
   * Nom de l'événement de la fiche
   */
  title: string = "";

  /**
   * Liste des documents
   */
  documents: SheetDocument[] = [];

  /**
   * Liste des commentaires
   */
  comments: SheetComment[] = [];

  /**
   * Liste des références bibliographiques
   */
  bibliography: SheetBibliography[] = [];

  /**
   * Liste étagée des événements parents/enfants de l'événement de la fiche
   */
  events: DataEvent[][] = [];
}