import * as _ from 'lodash';
import * as $ from 'jquery';

import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, OnDestroy, OnChanges } from '@angular/core';
import { Sheet, SheetComment, DataEvent, User } from 'src/app/entities';
import { NgForm } from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { SheetsService, UtilsService, AccountService } from 'src/app/shared/services';
import { SelectItem } from 'primeng/api/selectitem';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/shared/constants';

@Component({
  selector: 'sheet-infos',
  templateUrl: './sheet-infos.component.html'
})
export class SheetInfosComponent implements OnInit, OnDestroy, OnChanges {
  /**
   * Liste complète des événements par étage
   */
  @Input() events: DataEvent[][];

  /**
   * Événement à surligner
   */
  @Input() targetEvent: DataEvent;

  /**
   * Nouveau commentaire pour le formulaire d'ajout
   */
  public newComment: SheetComment;

  /**
   * Liste filtrée des événements à afficher
   */
  public filteredEvents: DataEvent[][] = [];

  /**
   * Liste des types d'événements pour les filtres
   */
  public types: SelectItem[] = [];

  /**
   * Liste des types d'événements sélectionnés dans le filtre
   */
  public selectedTypes: string[] = [];

  /**
   * Utilisateur actuel de l'application, si connecté
   */
  public currentUser: User;
  
  /**
   * Nom des étages
   */
  public stagesNames = Constants.STAGES_NAMES

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs = new Subscription();

  /**
   * Événement lancé à la demande d'enregistrement d'un commentaire
   */
  @Output() onNewComment = new EventEmitter<SheetComment>();

  /**
   * Liste des popovers de formulaire d'ajout de commentaire
   */
  @ViewChildren('commentPop') commentPopovers: QueryList<NgbPopover>;

  constructor(
    private _sheetService: SheetsService,
    private _utils: UtilsService,
    private _accountService: AccountService
  ) { }

  ngOnInit() {
    this._subs.add(this._accountService.currentUser$.subscribe(user => this.currentUser = user));
    this._subs.add(this._sheetService.filterTypes$.subscribe(types => this._filterEvents(types)));

    let types = this._sheetService.getEventTypeList(this.events);
    types = _.sortBy(types, t => this._utils.removeAccents(t));
    this.types = [];
    _.each(types, type => {
      this.types.push({ label: type, value: type });
    });
    this._filterEvents(types);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  ngOnChanges(changes) {
    if(changes.targetEvent && changes.targetEvent.currentValue) {
      setTimeout(() => {
        let $eventSection =  $('#event-' + changes.targetEvent.currentValue.id);
        let $parent = $eventSection.closest('.sheet-tab-content');

        $parent.scrollTop($parent.scrollTop() + $eventSection.position().top);
      }, 1);
    }
  }

  /**
   * Affiche le formulaire d'ajout de commentaire
   * @param event Événement auquel ajouter le commentaire
   * @param popover Référence de l'objet popover contenant le formulaire
   */
  public displayCommentForm(event: DataEvent, popover: NgbPopover, comment?: SheetComment) {
    if (!this.currentUser) {
      return;
    }
    if (!comment && this.newComment && !this.newComment.id && this.newComment.eventId === event.id) {
      this.closeCommentForm(popover);
    } else if (comment && this.newComment && this.newComment.id === comment.id) {
      this.closeCommentForm(popover);
    } else {
      this.closeCommentForm();
      if(comment) {
        this.newComment = _.cloneDeep(comment);
        delete this.newComment.event;
      } else {
        this.newComment = new SheetComment();
        this.newComment.eventId = event.id;
      }
      popover.open();
    }
  }

  /**
   * Ferme les formulaires de commentaire
   * @param popover Référence de la popover contenant le formulaire. Si fourni, lui seul est fermé
   */
  public closeCommentForm(popover?: NgbPopover) {
    if (popover) {
      popover.close();
    } else if (this.commentPopovers) {
      _.each(this.commentPopovers.toArray(), (p: NgbPopover) => {
        p.close();
      });
    }
    this.newComment = null;
  }

  /**
   * Formate le nouveau commentaire et demande son enregistrement au parent
   * @param commentForm Référence du formulaire
   */
  public saveComment(commentForm: NgForm) {
    if (this.currentUser && commentForm.valid) {
      this.newComment.date = new Date();
      this.onNewComment.emit(_.cloneDeep(this.newComment));
      this.closeCommentForm();
      this.newComment = null;
    }
  }

  /**
   * Affiche/cache les commentaires de l'événement
   * @param event Événement concerné
   */
  public displayComments(event: DataEvent) {
    if (event.comments.length > 0) {
      event.displayComments = !event.displayComments;
    }
  }

  /**
   * Filtre les événements affichés en fonction des types sélectionnés
   */
  public filterEventsByType() {
    this._sheetService.filterEventsByTypes(this.selectedTypes);
  }

  /**
   * Effectue le filtrage d'événements par type
   * @param types Liste des types à filtrer
   */
  private _filterEvents(types: string[]) {
    this.selectedTypes = types;
    this.filteredEvents = [];
    _.each(this.events, (stageEvents: DataEvent[]) => {
      let stageFilteredEvents = _.filter(stageEvents, e => types.indexOf(e.type) >= 0);
      this.filteredEvents.push(stageFilteredEvents);
    });
  }

}
