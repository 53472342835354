<div id="sheet-comments" class="d-flex flex-column h-100">
  <div class="sheet-tab-title d-flex align-items-center">
    <h2>Commentaires</h2>
    <div class="type-filter-container d-flex align-items-center ml-auto" *ngIf="eventsList.length > 0">
      <label for="sheet-comments-filter" class="mb-1 mr-2">Filtrer par type :</label>
      <div class="type-filter">
        <p-multiSelect [options]="eventsList" [(ngModel)]="selectedEventsIds" inputId="sheet-comments-filter"
          styleClass="w-100" class="w-100" [filter]="false" defaultLabel="Aucun événement sélectionné"
          selectedItemsLabel="{0} événements sélectionnés" [maxSelectedLabels]="2" (onChange)="filterEventsByEvent()">
        </p-multiSelect>
      </div>
    </div>
  </div>
  <div class="sheet-tab-content">
    <section class="comment-section" *ngFor="let comment of filteredComments">
      <div class="d-flex align-items-start">
        <h3 class="comment-title">
          De {{comment.author}}
          <small class="d-block">{{comment.event.title}}</small>
          <small class="d-block">Le {{comment.date | date:'dd/MM/yyyy'}} à {{comment.date | date:'HH:mm'}}</small>
        </h3>
        <ng-container *ngIf="currentUser && currentUser.username === comment.author">
          <button type="button" class="btn btn-icon btn-update-comment" i18n-title #commentPop="ngbPopover"
            [autoClose]="false" container="body" popoverClass="comment-form-popover" [ngbPopover]="commentFormContent"
            popoverTitle="Modifier le commentaire" triggers="manual" title="Modifier le commentaire"
            (click)="displayCommentForm(commentPop, comment)">
            <i class="fa fa-edit"></i>
          </button>
          <ng-template #commentFormContent>
            <form class="comment-form" #commentForm="ngForm" *ngIf="commentToUpdate"
              (ngSubmit)="saveComment(commentForm)" novalidate>
              <div class="form-group">
                <label for="new-comment">Contenu</label>
                <textarea name="new-comment" id="new-comment" class="form-control" [(ngModel)]="commentToUpdate.comment"
                  required>
              </textarea>
              </div>
              <div class="d-flex">
                <button class="btn btn-default" type="button" (click)="closeCommentForm(commentPop)">Annuler</button>
                <button class="btn btn-primary ml-auto" type="submit">Envoyer</button>
              </div>
            </form>
          </ng-template>
        </ng-container>
      </div>
      <p class="nl2br">{{comment.comment}}</p>
    </section>
  </div>
</div>