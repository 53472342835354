import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  /**
   * Source de demande de téléchargement
   */
  private _downloadSource = new Subject<string>();

  /**
   * Lancé à chaque demande de téléchargement
   */
  public download$ = this._downloadSource.asObservable();

  constructor(
    private _http: HttpClient
  ) { }

  //https://stackoverflow.com/questions/4726344/how-do-i-change-text-color-determined-by-the-background-color
  /**
   * Renvoie une couleur de texte noire ou blanche en fonction de la couleur de fond
   * @param bgColor Couleur de fond, en hexadécimal (ex: '#fa12e4')
   */
  public idealTextColor(bgColor: string): string {

    let nThreshold = 105;
    let components = this._getRGBComponents(bgColor);
    let bgDelta = (components.R * 0.299) + (components.G * 0.587) + (components.B * 0.114);

    return ((255 - bgDelta) < nThreshold) ? "#000000" : "#ffffff";
  }

  /**
   * Renvoie une version RGB d'une couleur hexadécimale
   * @param color Couleur en hexadécimal
   */
  private _getRGBComponents(color: string): { R: number, G: number, B: number } {

    let r = color.substring(1, 3);
    let g = color.substring(3, 5);
    let b = color.substring(5, 7);

    return {
      R: parseInt(r, 16),
      G: parseInt(g, 16),
      B: parseInt(b, 16)
    };
  }

  /**
   * Remplace les lettres accentuées par leur équivalent non accentuée
   * @param text - Texte à traiter
   */
  public removeAccents(text: string) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  }

  /**
   * Demande le téléchargement d'un fichier
   * @param url URL du fichier
   */
  public downloadFile(url: string) {
    this._downloadSource.next(url);
  }

  /**
   * Mesure la taille du texte donné
   * @param text Texte à mesurer
   */
  public measureText(text, fontSize) {
    let c = document.createElement("canvas");
    let ctx = c.getContext("2d");
    ctx.font = fontSize + "px Arial";
    return ctx.measureText(text).width;
  }
}
