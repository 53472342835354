import * as _ from 'lodash';
import * as $ from 'jquery';

import { Component, OnInit, OnDestroy, AfterViewInit, ViewChildren, QueryList, HostListener } from '@angular/core';
import { SectionPeriod } from 'src/app/entities';
import { ConfigurationService, NavigationService } from 'src/app/shared/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit, OnDestroy, AfterViewInit {
  /**
   * Liste des périodes
   */
  public periods: SectionPeriod[] = [];

  /**
   * Liste des périodes pré-mésozoïque
   */
  public paleoPeriods: SectionPeriod[] = [];

  /**
   * Liste des périodes mésozoïque et ultérieures
   */
  public otherPeriods: SectionPeriod[] = [];

  /**
   * Liste des sections dans la navigation
   */
  @ViewChildren('periodSection') periodSections: QueryList<any>;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs = new Subscription();

  constructor(
    private _configService: ConfigurationService,
    private _navigationService: NavigationService
  ) { }

  ngOnInit() {
    this._subs.add(this._configService.periods$.subscribe(periods => this._updatePeriods(periods)));
    this._subs.add(this._navigationService.navigation$.subscribe(navEvent => this._handleNavEvent(navEvent)));
    this._subs.add(this._navigationService.updateMap$.subscribe(event => this._updateMarkerPosition(event.targetSectionIdx, event.animationTime)));
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  ngAfterViewInit() {
    this._subs.add(this.periodSections.changes.subscribe(t => {
      this._updateMarkerPosition(null, 1);
    }));
    $('#main').scrollLeft(0);
  }

  @HostListener('window:resize')
  public onWindowResize() {
    this._updateMarkerPosition(null, 1);
  }

  /**
   * Demande une navigation vers la période indiquée
   * @param period Période où aller
   */
  public goToPeriod(period: SectionPeriod) {
    this._navigationService.navigateToSection(period.id, 400);
  }

  /**
   * Demande une navigation vers une période voisine
   * @param direction 'next' ou 'previous'
   */
  public goToNearbyPeriod(direction: string) {
    this._navigationService.navigateToSection(direction);
  }

  /**
   * Met à jour les listes des périodes
   * @param periods Périodes reçues du serveur
   */
  private _updatePeriods(periods: SectionPeriod[]) {
    this.periods = periods;
  }

  /**
   * Met à jour la position du marqueur de position de la frise
   * @param indexToGo (optionnel) Index de la destination directe
   * @param animationTime (optionnel) Temps d'animation du marker (300ms par défaut)
   */
  private _updateMarkerPosition(indexToGo?: number, animationTime?: number) {
    if (!_.isFinite(indexToGo)) {
      indexToGo = this._navigationService.getCurrentSectionIndex();
    }

    if (_.isFinite(indexToGo)) {
      let $positionMarker = $('.position-marker');
      let $mapItem = $('#timeline-map li').eq(indexToGo);
      $positionMarker.stop(true).animate({
        width: $mapItem.width() + 6,
        height: $mapItem.height() + 6,
        left: $mapItem.position().left,
        top: $mapItem.position().top
      }, animationTime || 300, 'linear');
    }
  }

  /**
   * Déplace le marqueur à l'événement de navigation
   * @param navEvent Événement de navigation
   */
  private _handleNavEvent(navEvent: any) {
    if (navEvent.animationTime) {
      setTimeout(() => this._updateMarkerPosition(), (navEvent.animationTime + 50));
    } else {
      this._updateMarkerPosition(null);
    }
  }
}
