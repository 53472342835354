import { User } from 'src/app/entities';
import { BehaviorSubject } from 'rxjs';
import { Uris } from '../constants/uris';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
import * as i2 from "@angular/common/http";
var AccountService = /** @class */ (function () {
    function AccountService(_toastr, _http) {
        this._toastr = _toastr;
        this._http = _http;
        /**
         * Source pour un unique utilisateur
         */
        this._currentUserSource = new BehaviorSubject(null);
        /**
         * Lancé à la récupération d'un unique utilisateur
         */
        this.currentUser$ = this._currentUserSource.asObservable();
    }
    /**
     * Vérifie si l'utilisateur est déjà connecté et applique sa connexion si c'est le cas
     */
    AccountService.prototype.isConnected = function () {
        var _this = this;
        this._http.get(Uris.USER_URL + "isConnected").subscribe(function (user) {
            if (user) {
                _this._currentUserSource.next(new User().deserialize(user));
            }
            else {
                _this.login();
            }
        }, function (error) {
            if (error && error.status === 401) {
                _this.login();
            }
        });
    };
    /**
     * Connecte un utilisateur
     */
    AccountService.prototype.login = function () {
        window.location.href = window.location.origin + "/back/";
    };
    /**
     * Déconnecte un utilisateur
     */
    AccountService.prototype.logout = function () {
        window.location.href = "" + window.location.origin + Uris.USER_URL + "logout";
    };
    AccountService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountService_Factory() { return new AccountService(i0.ɵɵinject(i1.ToastrService), i0.ɵɵinject(i2.HttpClient)); }, token: AccountService, providedIn: "root" });
    return AccountService;
}());
export { AccountService };
