import { EntityMapper } from './entity-mapper.model';
import { DataEvent } from './data-event.model';

/**
 * Configuration de l'application pour le jeu de données voulu
 */
export class AppConfig extends EntityMapper {
  /**
   * Titre principal du jeu de données
   */
  title: string = "Histoire géologique";

  /**
   * Sous-titre du jeu de données
   */
  subtitle: string = "(Établie dans le cadre du RGF)";

  /**
   * Liste à profondeur variable des événements du jeu de données
   */
  events: DataEvent[][] = [];
}