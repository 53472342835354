export class Constants {
  static STAGES_NAMES: string[] = [
    "Cycle de Wilson",
    "Cycle orogénique - Ordre %lvl",
    "Cycle orogénique - Ordre %lvl",
    "Evénements régionaux - Ordre %lvl",
    "Evénements locaux - Ordre %lvl",
    "Evénements élémentaires - Ordre %lvl",
    "Evénements élémentaires - Ordre %lvl",
    "Evénements élémentaires - Ordre %lvl",
    "Evénements élémentaires - Ordre %lvl",
    "Evénements élémentaires - Ordre %lvl",
    "Evénements élémentaires - Ordre %lvl",
    "Evénements élémentaires - Ordre %lvl",
    "Evénements élémentaires - Ordre %lvl",
    "Evénements élémentaires - Ordre %lvl",
    "Evénements élémentaires - Ordre %lvl"
  ];
}