import { EntityMapper } from './entity-mapper.model';
import { DataEvent } from './data-event.model';

/**
 * Commentaire d'événement
 */
export class SheetComment extends EntityMapper {

  /**
   * Id technique
   */
  id: number = null;

  /**
   * Auteur
   */
  author: string = "";

  /**
   * Date de dépôt
   */
  date: Date = null;

  /**
   * Contenu
   */
  comment: string = "";

  /**
   * ID de l'événement lié
   */
  eventId: number = null;

  /**
   * Événement lié
   */
  event: DataEvent = null;

  constructor() {
    super();

    this._mapperDefs = [
      { front: 'date', class: Date }
    ];
  }
}