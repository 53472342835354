<div class="d-flex flex-column h-100">
  <app-header></app-header>
  <main id="main" class="h-100 position-relative" navigationEvents (onNavigationEvent)="handleNavigationEvents($event)">

    <ul id="timeline-sections" class="d-flex" aria-hidden="true" role="presentation"
      [ngStyle]="{width: timelineWidth, height: (events.length > 0 ? timelineHeight + 'px' : '100%')}">
      <li *ngFor="let period of periods; let i = index" [ngStyle]="period.style">
        <div *ngIf="i > 0" class="gradient" [ngStyle]="{background: period.gradient || 'transparent'}"></div>
        <dl class="top-period-infos">
          <dt class="sr-only" i18n>Période</dt>
          <dd class="period-title">{{period.title}}</dd>
          <dt class="sr-only" i18n>Début de la période</dt>
          <dd class="period-start">
            {{period.start | numberDisplay}} <ng-container i18n>Ma</ng-container>
          </dd>
          <dt class="sr-only" i18n>Fin de la période</dt>
          <dd class="period-end">
            {{period.end | numberDisplay}} <ng-container i18n>Ma</ng-container>
          </dd>
        </dl>
      </li>
    </ul>

    <ul id="timeline-milestones" aria-hidden="true" role="presentation" [hidden]="events.length === 0" [ngStyle]="{
        width: timelineWidth,
        height: (timelineHeight - (stagesTops[stagesTops.length - 1] + 150)) + 'px',
        'min-height': 'calc(100% - ' + (stagesTops[stagesTops.length - 1] + 150) + 'px)',
        top: (stagesTops[stagesTops.length - 1] + 100 - stageMarginTop/2) + 'px'
      }">
      <li *ngFor="let milestone of milestones" [ngStyle]="milestone.style">
        <span>{{milestone.age}} Ma</span>
      </li>
    </ul>


    <div id="timeline" *ngIf="events">
      <ng-container *ngFor="let stageEvents of events; let stage = index">
        <div class="stage-separator" *ngIf="stage < 3" aria-hidden="true"
          [ngStyle]="{top: (stagesTops[stage] - (stageMarginTop/2) - 3) + 'px'}" [ngClass]="'stage-separator-' + stage">
          <span>{{stagesNames[stage].replace('%lvl', ' ' + stage)}}</span>
        </div>
        <ul class="stage" *ngIf="stage < 3" [ngClass]="'stage-' + stage" [ngStyle]="{top: stagesTops[stage] + 'px'}">
          <li class="period-item" *ngFor="let event of stageEvents">
            <span [ngStyle]="event.style" class="d-flex align-items-stretch"
              [ngClass]="{ 'not-real-duration': !event.isDisplayReal }" placement="top" [ngbTooltip]="tipContent"
              [disableTooltip]="event.isDisplayReal && !event.needTooltip">

              <span class="movable-text d-inline-flex align-items-center" [ngClass]="{'pr-2': stage < 2}">
                <span>{{event.title}}</span>
                <button class="btn btn-icon ml-2" *ngIf="stage === 2" i18n-title title="Voir la fiche de l'événement"
                  (click)="openSheet(event)">
                  <i class="fa fa-eye"></i>
                </button>
              </span>
              <ng-template #tipContent>
                <div class="text-left">
                  {{event.title}}
                  <ng-container *ngIf="!event.isDisplayReal">
                    <br />
                    Durée réelle: {{event.duration | numberDisplay}} Ma
                  </ng-container>
                </div>
              </ng-template>
            </span>
          </li>
        </ul>
      </ng-container>
    </div>
  </main>
  <app-navigation></app-navigation>
</div>
