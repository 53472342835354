import * as _ from 'lodash';
import * as $ from 'jquery';

import { Component, OnInit, OnDestroy, Host, HostListener } from '@angular/core';
import { DataEvent, SectionPeriod, AppConfig } from 'src/app/entities';
import { Subscription } from 'rxjs';
import { ConfigurationService, UtilsService, NavigationService, SheetsService } from 'src/app/shared/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SheetModalComponent } from '../../pages';
import { Constants } from 'src/app/shared/constants';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html'
})
export class TimelineComponent implements OnInit, OnDestroy {
  /**
   * Liste de tous les événements à afficher
   */
  public events: DataEvent[][] = [];

  /**
   * Liste de toutes les périodes pour le fond de la frise
   */
  public periods: SectionPeriod[] = [];

  /**
   * Jalons de l'échelle
   */
  public milestones: { age: number, style: any }[] = []

  /**
   * Largeur (en pixels) de la frise
   */
  public timelineWidth: string = 'auto';

  /**
   * Hauteur (en pixels) de la frise
   */
  public timelineHeight: number = 2000;

  /**
   * Placement Y des d'étages
   */
  public stagesHeights: [number, number, number] = [0, 0, 0];

  /**
   * Placement Y des d'étages
   */
  public stagesTops: [number, number, number] = [0, 0, 0];

  /**
   * Nom des étages
   */
  public stagesNames = Constants.STAGES_NAMES

  /**
   * Marge top de chaque étage
   */
  public stageMarginTop = 30;

  /**
   * Largeur calculée (en pixels) pour 1 Ma
   */
  private maWidth: number = 1;

  /**
   * Dernière position X du touch/cliquer-glisser
   */
  private _lastTouchPosition: number;

  /**
   * Valeur en Ma remplaçant la durée réelle du Quaternaire pour un affichage correct
   */
  private _lastSectionFalseDuration: number = 14;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs = new Subscription();

  constructor(
    private _configService: ConfigurationService,
    private _navigationService: NavigationService,
    private _sheetService: SheetsService,
    private _modalService: NgbModal,
    private _utils: UtilsService
  ) { }

  ngOnInit() {
    this._subs.add(this._configService.periods$.subscribe(periods => this._updatePeriods(periods)));
    this._subs.add(this._configService.config$.subscribe(config => this._updateEvents(config)));
    this._subs.add(this._navigationService.navigation$.subscribe(navEvent => this._goToDestination(navEvent)));

    this._configService.getPeriods();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Réagit aux actions clavier/souris pour demander une navigation
   * @param event Événement de clavier/souris
   */
  public handleNavigationEvents(event) {
    switch (event.type) {
      case "touchstart": this._lastTouchPosition = event.positionX;
        break;
      case "touchmove": let delta = this._lastTouchPosition - event.positionX;
        this._lastTouchPosition = event.positionX;
        this._navigationService.navigateTo($('#main').scrollLeft() + delta);
        break;
      case "right": this._navigationService.navigateTo($('#main').scrollLeft() + $(window).width(), 200);
        break;
      case "left": this._navigationService.navigateTo($('#main').scrollLeft() - $(window).width(), 200);
        break;
      case "previous":
      case "next": this._navigationService.navigateToSection(event.type);
        break;
    }
  }

  /**
   * Ouvre la page d'informations de l'événement sélectionné
   * @param events Liste contenant l'événement sélectionné et ses parents
   */
  public openSheet(event: DataEvent) {
    let hierarchyEvents = this._sheetService.getEventHierarchy(event, this.events);

    let color = this.periods[0].color;
    for (let i = 0; i < this.periods.length; i++) {
      color = this.periods[i].color;
      if (this.periods[i].end > event.start) {
        break;
      }
    }

    let modalRef = this._modalService.open(SheetModalComponent, { windowClass: 'modal-lg sheet-modal' });
    modalRef.componentInstance.baseEvent = event;
    modalRef.componentInstance.events = hierarchyEvents;
    modalRef.componentInstance.mainColor = color;
  }

  /**
   * Met à jour les périodes de fond de la frise
   * @param periods Liste des périodes reçues du serveur
   */
  private _updatePeriods(periods: SectionPeriod[]) {
    this.periods = periods;
    this._generatePeriodsStyles();
    this._configService.getConfig();
  }

  /**
   * Met à jour la taille et la couleur des périodes
   */
  private _generatePeriodsStyles() {
    let tinyiestPeriod = _.minBy(this.periods, p => (p.end - p.start));
    let tinyiestDuration = tinyiestPeriod.end - tinyiestPeriod.start;
    let tinyiestWidth = 80; // en pixels

    this.maWidth = tinyiestWidth / tinyiestDuration;

    let timelineWidth = 0;
    _.each(this.periods, (period: SectionPeriod, i: number) => {
      var periodDuration = period.end - period.start;
      if (periodDuration < this._lastSectionFalseDuration) { // trick pour le quaternaire qui est trop petit
        periodDuration = this._lastSectionFalseDuration;
      }
      var periodWidth = periodDuration * this.maWidth;
      timelineWidth += periodWidth;
      period.style['min-width'] = periodWidth + 'px';
      period.style['max-width'] = periodWidth + 'px';
      period.style['background'] = period.color;
      period.style['color'] = this._utils.idealTextColor(period.color);
      if (i > 0) {
        let prevColor = this.periods[i - 1].color;

        period.gradient = `linear-gradient(90deg, ${prevColor} 0%, ${period.color} 100%)`;
      }
    });

    this.timelineWidth = timelineWidth + 'px';
  }

  /**
   * Met à jour les événéments à afficher
   * @param config Configuration reçue du serveur
   */
  private _updateEvents(config: AppConfig) {
    this.events = config.events;

    this.stagesHeights = [0, 0, 0];

    this._updateEventsStyle();

    _.each(this.stagesTops, (t, stage) => {
      if (stage === 0) {
        this.stagesTops[stage] = 0;
      } else {
        this.stagesTops[stage] = _.sum(this.stagesHeights.slice(0, stage));
        this.stagesTops[stage] += stage * this.stageMarginTop;
      }
    })

    let timelineHeight = 92 + 90; //indicateurs haut et bas
    _.each(this.stagesHeights, (height, i) => {
      timelineHeight += height;
      if (i > 0) {
        timelineHeight += this.stageMarginTop;
      }
    });
    this.timelineHeight = timelineHeight;

    let farestPeriod: SectionPeriod = _.minBy(this.periods, 'start');
    let nearestPeriod: SectionPeriod = _.maxBy(this.periods, 'end');

    let totalDuration = nearestPeriod.end - farestPeriod.start;

    let milestonesMa = 10;

    for (let i = milestonesMa; i <= totalDuration; i += milestonesMa) {
      this.milestones.push({
        age: -(totalDuration - i),
        style: {
          left: (i * this.maWidth) + 'px'
        }
      });
    }
  }

  /**
   * Applique la demande de navigation vers une position dans la frise
   * @param navEvent Événement de navigation
   */
  private _goToDestination(navEvent: any) {
    if (navEvent.animationTime) {
      this._updateSeparatorsPositions(navEvent.destination, navEvent.animationTime);
      $('#main').stop(true).animate({
        scrollLeft: navEvent.destination
      }, navEvent.animationTime, 'linear', () => {
        this._updateTitlesPositions(200);
      });
    } else {
      $('#main').scrollLeft(navEvent.destination);
      this._updateTitlesPositions();
      this._updateSeparatorsPositions();
    }
  }

  /**
   * Met à jour le placement et la taille des événements sur la frise
   */
  private _updateEventsStyle() {
    let farestPeriod: SectionPeriod = _.minBy(this.periods, 'start');
    let farestDate = farestPeriod.start;
    _.each(this.events, (stageEvents: DataEvent[], stage: number) => {
      _.each(stageEvents, (event: DataEvent) => {
        if (stage < 3) {
          event.style = {};
          // placement gauche
          let leftPosition = ((event.start - farestDate) * this.maWidth);
          event.style.left = leftPosition + 'px';

          // taille de l'élément
          let eventDuration = event.duration;
          if (event.end > -2.58) {  // pour les événements débordants sur le quaternaire, revue de l'échelle
            let remainingTime = event.end - -2.58;
            eventDuration -= remainingTime;
            eventDuration += (remainingTime / 2.58) * this._lastSectionFalseDuration;
          }
          let eventWidth = eventDuration * this.maWidth;
          if (eventWidth < 120) {
            eventWidth = 120;
            event.isDisplayReal = false;
          }
          event.style.width = eventWidth + 'px';

          let contentWidth = this._utils.measureText(event.title, 16) + 8 + 10;
          if (stage === 2) {
            contentWidth += 41;
          }

          if (contentWidth > eventWidth) {
            event.needTooltip = true;
          }

          // placement haut
          let top = 0;

          let occupiedTops = [];
          _.each(stageEvents, (e: DataEvent) => {
            if (e.style && e !== event) {
              let eLeft = Number(e.style.left.replace('px', ''));
              let eWidth = Number(e.style.width.replace('px', ''));
              if (eLeft <= (leftPosition + eventWidth) && (eLeft + eWidth) >= leftPosition) {
                occupiedTops.push(Number(e.style.top.replace('px', '')));
              }
            }
          });

          while (occupiedTops.indexOf(top) >= 0) {
            top += 34; // 34px (30px de haut + écarts)
          }

          event.style.top = top + 'px';

          if (stage < 3 && (top + 34) > this.stagesHeights[stage]) {
            this.stagesHeights[stage] = top + 34;
          }
        }
      });
    })
  }

  /**
   * Met à jour la position des séparateurs d'étages
   * @param destination (optionnel) Destination (en pixels) de l'animation
   * @param animationTime (optionnel) Temps d'animation du changement de position
   */
  private _updateSeparatorsPositions(destination?: number, animationTime?: number) {
    let currentLeftPos = _.isFinite(destination) ? destination : $('#main').scrollLeft();
    if (currentLeftPos < 0) {
      currentLeftPos = 0;
    }
    if (currentLeftPos + $(window).width() > $('#timeline-sections').width()) {
      currentLeftPos = $('#timeline-sections').width() - $(window).width();
    }
    if (animationTime) {
      $('.stage-separator').stop(true).animate({
        left: currentLeftPos
      }, animationTime, 'linear');
    } else {
      $('.stage-separator').css({
        left: currentLeftPos
      });
    }
  }

  /**
   * Met à jour la position des titres d'événements dans leur cadre, en fonction du scroll de la frise
   * @param animationTime (optionnel) Temps d'animation du déplacement. Déplacement instantané si non fourni
   */
  private _updateTitlesPositions(animationTime?: number) {
    let currentLeftPos = $('#main').scrollLeft();
    if (currentLeftPos < 0) {
      currentLeftPos = 0;
    }
    if (currentLeftPos + $(window).width() > $('#timeline-sections').width()) {
      currentLeftPos = $('#timeline-sections').width() - $(window).width();
    }

    $('#timeline .movable-text').each(function () { // obligatoire pour garder le contexte jQuery      
      let $parent = $(this).parent();

      let leftPos = $parent.offset().left + currentLeftPos;
      let textLeftPos = 0;

      if (leftPos < currentLeftPos) {
        textLeftPos = currentLeftPos - leftPos;
      }

      let textRightPos = $(this).outerWidth() + textLeftPos;

      if (textRightPos > $parent.width()) {
        textLeftPos = $parent.width() - $(this).outerWidth();
      }
      if (animationTime) {
        $(this).stop(true).animate({
          left: textLeftPos
        }, animationTime);
      } else {
        $(this).css({
          left: textLeftPos
        });
      }
    });
  }
}
