import { EntityMapper } from './entity-mapper.model';

/**
 * Utilisateur de l'application
 */
export class User extends EntityMapper {
  
  /**
   * Email
   */
  email: string = "";

  /**
   * Nom d'usage
   */
  username: string = "";

  constructor() {
    super();
    this._mapperDefs = [ { front: 'username', back: 'email' } ];
  }

}