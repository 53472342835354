import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilsService, AccountService } from './shared/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'fiche-evenementielle';
  public downloadUrl;
  isApplicationReady = false;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs = new Subscription();

  constructor(
    private _utils: UtilsService,
    private _accountService: AccountService
  ) { 
  
    this._subs.add(this._accountService.currentUser$.subscribe(user => this.manageConnection(user)));

    //test si on est connecté pour lancer la connection au démarrage
    _accountService.isConnected();
  }

  ngOnInit() {
    this._utils.download$.subscribe(url => {
      this.downloadUrl = url;
      setTimeout(() => { this.downloadUrl = null }, 500);
    });
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  manageConnection(user) {
    this.isApplicationReady = (user != null);
  }
}
