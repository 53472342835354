import * as _ from 'lodash';
import * as $ from 'jquery';

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  /**
   * Source de demande de navigation
   */
  private _navigationSource = new Subject<any>();

  /**
   * Lancé à chaque de demande de navigation
   */
  public navigation$ = this._navigationSource.asObservable();

  /**
   * Source de demande de mise à jour de la map de la frise
   */
  private _updateMapSource = new Subject<any>();

  /**
   * Lancé à chaque demande de mise à jour de la map de la frise
   */
  public updateMap$ = this._updateMapSource.asObservable();

  constructor() { }

  /**
   * Demande une navigation
   * @param destination Position X de destination, en pixels
   * @param animationTime (optionnel) Temps d'animation de la navigation, en ms (0 par défaut)
   */
  public navigateTo(destination: number, animationTime?: number) {
    this._navigationSource.next({
      destination: destination,
      animationTime: animationTime || 0
    });
  }
  
  /**
   * Demande une navigation vers une période précise
   * @param idx Index de la période ou indication de son voisinage ('next' ou 'previous')
   * @param animationTime (optionnel) Temps d'animation de la navigation, en ms (0 par défaut)
   */
  public navigateToSection(idx: number | string, animationTime?: number) {
    if (_.isString(idx)) {
      if (idx === 'next') {
        idx = this.getCurrentSectionIndex() + 1;
      } else if (idx === 'previous') {
        idx = this.getCurrentSectionIndex() - 1;
      }
    }

    if(idx < 0) {
      idx = 0;
    }

    if (_.isFinite(idx) && idx >= 0 && idx < $('#timeline-sections li').length) {
      let $section = $('#timeline-sections li').eq(idx);
      if($section.length > 0) {
        this.updateMap(animationTime || 300, idx as number);
        this.navigateTo($section.offset().left + $('#main').scrollLeft() - 55, animationTime || 300);
      }
    }
  }

  /**
   * Demande la mise à jour de la map de la frise
   * @param animationTime (optionnel) Temps d'animation de la navigation, en ms (0 par défaut)
   * @param targetSectionIdx (optionnel) Index de la période ciblée
   */
  public updateMap(animationTime?: number, targetSectionIdx?: number) {
    this._updateMapSource.next({
      animationTime: animationTime || 0,
      targetSectionIdx: _.isFinite(targetSectionIdx) ? targetSectionIdx : null
    });
  }

  /**
   * Renvoie l'index de la section dans laquelle se trouve actuellement le côté gauche de l'écran
   */
  public getCurrentSectionIndex(): number {
    var index = null;
    var position = $('#main').scrollLeft();
    $('#timeline-sections li').each(function (i) {
      var itemPosition = $(this).offset().left + position - 60;
      if (position >= itemPosition && position < (itemPosition + $(this).width())) {
        index = i;
        return false;
      }
    });
    return index;
  }
}
