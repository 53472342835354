<div id="sheet-infos" class="d-flex flex-column h-100">
  <div class="sheet-tab-title d-flex align-items-center">
    <h2>Événements régionaux et locaux</h2>
    <div class="type-filter-container d-flex align-items-center ml-auto">
      <label for="sheet-infos-filter" class="mb-1 mr-2">Filtrer par type :</label>
      <div class="type-filter">
        <p-multiSelect [options]="types" [(ngModel)]="selectedTypes" inputId="sheet-infos-filter" styleClass="w-100"
          class="w-100" [filter]="false" defaultLabel="Aucun type sélectionné"
          selectedItemsLabel="{0} types sélectionnés" [maxSelectedLabels]="2" (onChange)="filterEventsByType()">
        </p-multiSelect>
      </div>
    </div>
  </div>
  <div class="sheet-tab-content">
    <ng-container *ngFor="let stageEvents of filteredEvents; let stage = index;">
      <div [id]="'details-' + stage" class="section-separator" *ngIf="stageEvents.length > 0">
        <span *ngIf="stagesNames.length > stage">{{stagesNames[stage].replace('%lvl', ' ' + stage)}}</span>
        <span *ngIf="stagesNames.length <= stage">Niveau {{stage}}</span>
      </div>
      <section class="info-section" [id]="'event-' + event.id" *ngFor="let event of stageEvents">
        <h3 class="event-title">
          {{event.title}}
          <small class="d-block">{{event.type}}</small>
          <small class="d-block">De {{event.start | numberDisplay}} Ma à {{event.end | numberDisplay}} Ma</small>
        </h3>
        <p class="mb-0" [innerHtml]="event.details | sanitizeHtml">{{event.details}}</p>
        <div class="d-flex align-items-end">
          <button type="button" class="btn toggle-comments-btn" i18n-title title="Voir les commentaires"
            (click)="displayComments(event)">
            <i class="fa fa-comment mr-2"></i>
            <ng-container [ngSwitch]="event.comments.length">
              <ng-container *ngSwitchCase="0">Aucun commentaire</ng-container>
              <ng-container *ngSwitchCase="1">1 commentaire</ng-container>
              <ng-container *ngSwitchDefault>{{event.comments.length}} commentaires</ng-container>
            </ng-container>
          </button>

          <div class="ml-auto position-relative" *ngIf="currentUser">
            <button type="button" class="btn btn-icon btn-add-comment" i18n-title #commentPop="ngbPopover"
              [autoClose]="false" container="body" popoverClass="comment-form-popover" [ngbPopover]="commentFormContent"
              popoverTitle="Ajouter un commentaire" triggers="manual" title="Ajouter un commentaire à cet événement"
              (click)="displayCommentForm(event, commentPop)">
              <i class="fa fa-comment-medical"></i>
            </button>
            <ng-template #commentFormContent>
              <form class="comment-form" #commentForm="ngForm" *ngIf="newComment" (ngSubmit)="saveComment(commentForm)"
                novalidate>
                <div class="form-group">
                  <label for="new-comment">Contenu</label>
                  <textarea name="new-comment" id="new-comment" class="form-control" [(ngModel)]="newComment.comment"
                    required>
                  </textarea>
                </div>
                <div class="d-flex">
                  <button class="btn btn-default" type="button" (click)="closeCommentForm(commentPop)">Annuler</button>
                  <button class="btn btn-primary ml-auto" type="submit">Envoyer</button>
                </div>
              </form>
            </ng-template>
          </div>
        </div>
        <div class="comments-container" [hidden]="!event.displayComments">
          <h4 class="comments-title">Commentaires</h4>
          <div class="event-comment" *ngFor="let comment of event.comments">
            <div class="d-flex align-items-start">
              <h5 class="comment-title">
                De {{comment.author}}
                <small class="d-block">
                  Le {{comment.date | date:'dd/MM/yyyy'}} à {{comment.date | date:'HH:mm'}}
                </small>
              </h5>
              <ng-container *ngIf="currentUser && currentUser.username === comment.author">
                <button type="button" class="btn btn-icon btn-update-comment" i18n-title #commentPop="ngbPopover"
                  [autoClose]="false" container="body" popoverClass="comment-form-popover"
                  [ngbPopover]="commentFormContent" popoverTitle="Modifier le commentaire" triggers="manual"
                  title="Modifier le commentaire" (click)="displayCommentForm(event, commentPop, comment)">
                  <i class="fa fa-edit"></i>
                </button>
                <ng-template #commentFormContent>
                  <form class="comment-form" #commentForm="ngForm" *ngIf="newComment"
                    (ngSubmit)="saveComment(commentForm)" novalidate>
                    <div class="form-group">
                      <label for="new-comment">Contenu</label>
                      <textarea name="new-comment" id="new-comment" class="form-control"
                        [(ngModel)]="newComment.comment" required>
                      </textarea>
                    </div>
                    <div class="d-flex">
                      <button class="btn btn-default" type="button"
                        (click)="closeCommentForm(commentPop)">Annuler</button>
                      <button class="btn btn-primary ml-auto" type="submit">Envoyer</button>
                    </div>
                  </form>
                </ng-template>
              </ng-container>
            </div>
            <p class="nl2br">{{comment.comment}}</p>
          </div>
        </div>
      </section>
    </ng-container>
  </div>
</div>
