<div class="d-flex flex-column h-100">
  <div class="modal-header" [ngStyle]="{'border-color': mainColor}">
    <h1 class="modal-title" id="modal-basic-title" *ngIf="sheet">{{sheet.title}}</h1>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="sheet">
    <div class="gradient-background"
      [ngStyle]="{'background': 'linear-gradient(90deg, transparent, ' + mainColor + ' 50%)'}"></div>
    <div class="modal-body-content h-100">
      <ngb-tabset #tabs="ngbTabset" type="pills" orientation="vertical" activeId="tree" [destroyOnHide]="false"
        class="d-flex align-items-start h-100">
        <ngb-tab id="tree">
          <ng-template ngbTabTitle>
            <i class="fa fa-sitemap"></i>
            <span class="d-flex align-items-center">
              Relations entre <br>
              les événements
            </span>
          </ng-template>
          <ng-template ngbTabContent>
            <sheet-tree class="h-100" [events]="sheet.events" [baseEventId]="baseEvent.id"
              (onDisplayEvent)="goToEvent($event)"></sheet-tree>
          </ng-template>
        </ngb-tab>
        <ngb-tab id="details">
          <ng-template ngbTabTitle>
            <i class="fa fa-info-circle"></i>
            <span class="d-flex align-items-center">
              Événements <br>
              régionaux et locaux
            </span>
          </ng-template>
          <ng-template ngbTabContent>
            <sheet-infos class="h-100" [events]="sheet.events" [targetEvent]="targetEvent"
              (onNewComment)="saveComment($event)">
            </sheet-infos>
          </ng-template>
        </ngb-tab>
        <ngb-tab id="documents">
          <ng-template ngbTabTitle>
            <i class="fa fa-images"></i>
            <span class="d-flex align-items-center">Documents</span>
          </ng-template>
          <ng-template ngbTabContent>
            <sheet-documents class="h-100" [documents]="sheet.documents"></sheet-documents>
          </ng-template>
        </ngb-tab>
        <ngb-tab id="biblio">
          <ng-template ngbTabTitle>
            <i class="fa fa-book"></i>
            <span class="d-flex align-items-center">Bibliographie</span>
          </ng-template>
          <ng-template ngbTabContent>
            <sheet-biblio class="h-100" [biblios]="sheet.bibliography"></sheet-biblio>
          </ng-template>
        </ngb-tab>
        <ngb-tab id="comments">
          <ng-template ngbTabTitle>
            <i class="fa fa-comment"></i>
            <span class="d-flex align-items-center">Commentaires</span>
          </ng-template>
          <ng-template ngbTabContent>
            <sheet-comments class="h-100" [comments]="sheet.comments" (onUpdateComment)="saveComment($event)">
            </sheet-comments>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
</div>