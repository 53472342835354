import * as tslib_1 from "tslib";
import { EntityMapper } from './entity-mapper.model';
/**
 * Fiche d'information d'un événement
 */
var Sheet = /** @class */ (function (_super) {
    tslib_1.__extends(Sheet, _super);
    function Sheet() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * Nom de l'événement de la fiche
         */
        _this.title = "";
        /**
         * Liste des documents
         */
        _this.documents = [];
        /**
         * Liste des commentaires
         */
        _this.comments = [];
        /**
         * Liste des références bibliographiques
         */
        _this.bibliography = [];
        /**
         * Liste étagée des événements parents/enfants de l'événement de la fiche
         */
        _this.events = [];
        return _this;
    }
    return Sheet;
}(EntityMapper));
export { Sheet };
