import * as tslib_1 from "tslib";
import { EntityMapper } from './entity-mapper.model';
/**
 * Commentaire d'événement
 */
var SheetComment = /** @class */ (function (_super) {
    tslib_1.__extends(SheetComment, _super);
    function SheetComment() {
        var _this = _super.call(this) || this;
        /**
         * Id technique
         */
        _this.id = null;
        /**
         * Auteur
         */
        _this.author = "";
        /**
         * Date de dépôt
         */
        _this.date = null;
        /**
         * Contenu
         */
        _this.comment = "";
        /**
         * ID de l'événement lié
         */
        _this.eventId = null;
        /**
         * Événement lié
         */
        _this.event = null;
        _this._mapperDefs = [
            { front: 'date', class: Date }
        ];
        return _this;
    }
    return SheetComment;
}(EntityMapper));
export { SheetComment };
