import { EntityMapper } from './entity-mapper.model';
import { DataEvent } from './data-event.model';

/**
 * Référence bibliographique
 */
export class SheetBibliography extends EntityMapper {
  /**
   * Url vers la référence bibliographique
   */
  url: string = "";

  /**
   * Nom
   */
  title: string = "";

  /**
   * Description
   */
  description: string = "";

  /**
   * ID de l'événement concerné
   */
  eventId: number = null;

  /**
   * Événement concerné
   */
  event: DataEvent = null;
}