import * as _ from 'lodash';

import { Component, OnInit, Input } from '@angular/core';
import { SheetBibliography } from 'src/app/entities';
import { SelectItem } from 'primeng/api/selectitem';

@Component({
  selector: 'sheet-biblio',
  templateUrl: './sheet-biblio.component.html'
})
export class SheetBiblioComponent implements OnInit {
  /**
   * Liste des bibliographies
   */
  @Input() biblios: SheetBibliography[];

  /**
   * Liste filtrée des bibliographies
   */
  public filteredBiblios: SheetBibliography[] = [];

  /**
   * Liste des Ids des événements sélectionnés dans le filtre
   */
  public selectedEventsIds: number[] = [];

  /**
   * Liste des événements pour le filtre
   */
  public eventsList: SelectItem[] = [];

  ngOnInit() {
    _.each(this.biblios, (biblio: SheetBibliography) => {
      if (this.selectedEventsIds.indexOf(biblio.eventId) < 0) {
        this.eventsList.push({ label: biblio.event.title, value: biblio.eventId });
        this.selectedEventsIds.push(biblio.eventId);
      }
    });

    this.filterEventsByEvent();
  }

  /**
   * Filtre la liste des bibliographies en fonction des événements sélectionnés
   */
  public filterEventsByEvent() {
    this.filteredBiblios = _.filter(this.biblios, d => this.selectedEventsIds.indexOf(d.eventId) >= 0);
  }

}
