<div id="sheet-biblio" class="d-flex flex-column h-100">
  <div class="sheet-tab-title d-flex align-items-center">
    <h2>Bibliographie</h2>
    <div class="type-filter-container d-flex align-items-center ml-auto" *ngIf="eventsList.length > 0">
      <label for="sheet-biblios-filter" class="mb-1 mr-2">Filtrer par type :</label>
      <div class="type-filter">
        <p-multiSelect [options]="eventsList" [(ngModel)]="selectedEventsIds" inputId="sheet-biblios-filter"
          styleClass="w-100" class="w-100" [filter]="false" defaultLabel="Aucun événement sélectionné"
          selectedItemsLabel="{0} événements sélectionnés" [maxSelectedLabels]="2" (onChange)="filterEventsByEvent()">
        </p-multiSelect>
      </div>
    </div>
  </div>
  <div class="sheet-tab-content">
    <ul class="row">
      <li class="col-3 item" *ngFor="let ref of filteredBiblios">
        <dl>
          <dt>{{ref.title}}</dt>
          <dd>
            <em class="d-block">{{ref.event.title}}</em>
            <a class="d-block" [href]="ref.url" target="_blank">
              {{ref.url}}
              <i class="fa fa-external-link-alt ml-2"></i>
            </a>
            <p class="mb-0">{{ref.description}}</p>
          </dd>
        </dl>
      </li>
    </ul>
  </div>
</div>