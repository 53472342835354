import * as tslib_1 from "tslib";
import { EntityMapper } from './entity-mapper.model';
/**
 * Configuration de l'application pour le jeu de données voulu
 */
var AppConfig = /** @class */ (function (_super) {
    tslib_1.__extends(AppConfig, _super);
    function AppConfig() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * Titre principal du jeu de données
         */
        _this.title = "Histoire géologique";
        /**
         * Sous-titre du jeu de données
         */
        _this.subtitle = "(Établie dans le cadre du RGF)";
        /**
         * Liste à profondeur variable des événements du jeu de données
         */
        _this.events = [];
        return _this;
    }
    return AppConfig;
}(EntityMapper));
export { AppConfig };
