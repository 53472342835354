import * as _ from 'lodash';

import { Component, OnInit, Input } from '@angular/core';
import { Sheet, SheetDocument } from 'src/app/entities';
import { SelectItem } from 'primeng/api/selectitem';
import { UtilsService } from 'src/app/shared/services';

@Component({
  selector: 'sheet-documents',
  templateUrl: './sheet-documents.component.html'
})
export class SheetDocumentsComponent implements OnInit {
  /**
   * Liste des documents
   */
  @Input() documents: SheetDocument[];

  /**
   * Liste filtrée des documents
   */
  public filteredDocuments: SheetDocument[] = [];

  /**
   * Liste des IDs des événements sélectionnés dans le filtre
   */
  public selectedEventsIds: number[] = [];

  /**
   * Liste des événements pour le filtre
   */
  public eventsList: SelectItem[] = [];

  /**
   * Document actuellement visionné
   */
  public focusedDocument: SheetDocument;

  constructor(
    private _utils: UtilsService
  ) { }

  ngOnInit() {
    _.each(this.documents, (document: SheetDocument) => {
      if (this.selectedEventsIds.indexOf(document.eventId) < 0) {
        this.eventsList.push({ label: document.event.title, value: document.eventId });
        this.selectedEventsIds.push(document.eventId);
      }
    });

    this.filterEventsByEvent();
  }

  /**
   * Filtre la liste des commentaires en fonction des événements sélectionnés
   */
  public filterEventsByEvent() {
    this.filteredDocuments = _.filter(this.documents, d => this.selectedEventsIds.indexOf(d.eventId) >= 0);
  }

  /**
   * Ouvre une lightbox pour visualiser le document si c'est possible, sinon télécharge le document
   * @param document 
   */
  public focusDocument(document: SheetDocument) {
    if(['image', 'pdf'].indexOf(document.type) >= 0) {
      this.focusedDocument = document;
    } else {
      this._utils.downloadFile(document.url);
    }
  }

  /**
   * Ferme la lightbox de visualisation du document
   */
  public closeLightbox() {
    this.focusedDocument = null;
  }

}
