<header id="header" class="header d-flex align-items-center p-3">
  <a href="#main" class="sr-only">Aller directement au contenu</a>
  <div class="logo mr-3">
    <a href="http://www.brgm.fr" class="d-block" title="Aller sur le site du BRGM" target="_blank">
      <img alt="Geosciences pour une Terre durable, BRGM" src="/assets/images/logo-brgm.png">
    </a>
  </div>
  <h1>
    <ng-container *ngIf="!title">Histoire géologique</ng-container>
    <ng-container *ngIf="title">{{title}}</ng-container>
    <small class="d-block" *ngIf="title">{{subtitle}}</small>
  </h1>
  <div class="account ml-auto align-self-start" [ngClass]="{logged: currentUser}" ngbDropdown>
    <button class="btn btn-account" id="account-menu" ngbDropdownToggle>
      <ng-container *ngIf="!currentUser">Non connecté</ng-container>
      <ng-container *ngIf="currentUser">{{currentUser.username}}</ng-container>
      <i class="fa fa-user ml-2 mr-1"></i>
    </button>
    <div ngbDropdownMenu aria-labelledby="account-menu">
      <button *ngIf="!currentUser" (click)="login()" ngbDropdownItem>Se connecter</button>
      <button *ngIf="currentUser" (click)="logout()" ngbDropdownItem>Déconnexion</button>
    </div>
  </div>
</header>