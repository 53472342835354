import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModalModule, NgbTabsetModule, NgbPopoverModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { ToastrModule } from 'ngx-toastr';

import { MultiSelectModule } from 'primeng/multiselect';
import { LightboxModule } from 'primeng/lightbox';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  TimelineComponent, HeaderComponent, NavigationComponent, SheetTreeComponent,
  SheetInfosComponent, SheetBiblioComponent, SheetCommentsComponent, SheetDocumentsComponent
} from './core/layout';
import { SheetModalComponent } from './core/pages';
import { NumberDisplayPipe, SanitizeHtmlPipe, SanitizeUrlPipe } from './shared/pipes';
import { NavigationEventsDirective } from './shared/directives';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    TimelineComponent,
    HeaderComponent,
    NavigationComponent,
    NumberDisplayPipe,
    SanitizeHtmlPipe,
    SanitizeUrlPipe,
    NavigationEventsDirective,
    SheetModalComponent,
    SheetTreeComponent,
    SheetInfosComponent,
    SheetBiblioComponent,
    SheetCommentsComponent,
    SheetDocumentsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    BrowserAnimationsModule,
    NgbModalModule,
    NgbTabsetModule,
    NgbPopoverModule, // required animations module,
    NgbDropdownModule,
    NgbTooltipModule,
    ToastrModule.forRoot({
      closeButton: true,
      progressBar: true,
      resetTimeoutOnDuplicate: true,
      preventDuplicates: true,
      positionClass: 'toast-bottom-right'
    }),
    MultiSelectModule,
    LightboxModule
  ],
  providers: [],
  entryComponents: [
    SheetModalComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
