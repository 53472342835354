import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SectionPeriod, AppConfig, DataEvent } from 'src/app/entities';
import { Uris } from '../constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ConfigurationService = /** @class */ (function () {
    function ConfigurationService(_http) {
        this._http = _http;
        /**
         * Source utilisée pour les listes de périodes
         */
        this._periodsSource = new Subject();
        /**
         * Source utilisée pour la configuration
         */
        this._configSource = new Subject();
        /**
         * Observable lancé à chaque réception d'une liste de périodes
         */
        this.periods$ = this._periodsSource.asObservable();
        /**
         * Observable lancé à chaque réception d'une configuration
         */
        this.config$ = this._configSource.asObservable();
    }
    /**
     * Demande la récupération des périodes
     */
    ConfigurationService.prototype.getPeriods = function () {
        var _this = this;
        this._http.get(Uris.PERIODS)
            .pipe(map(function (periods) {
            _.each(periods, function (period, i) {
                period.id = i;
            });
            return periods.map(function (p) { return new SectionPeriod().deserialize(p); });
        }))
            .subscribe(function (periods) { return _this._periodsSource.next(periods); });
    };
    /**
     * Demande la récupération de la configuration
     */
    ConfigurationService.prototype.getConfig = function () {
        var _this = this;
        this._http.get(Uris.CONFIG)
            .pipe(map(function (c) {
            var events = c.events.map(function (e) { return new DataEvent().deserialize(e); });
            var finalEvents = [];
            var maxDepthEvent = _.maxBy(events, 'rank');
            for (var i = 0; i <= maxDepthEvent.rank; i++) { // les ranks démarrent à 0
                finalEvents.push([]);
            }
            _.each(events, function (event) {
                finalEvents[event.rank].push(event);
            });
            _.each(finalEvents, function (stageEvents, i) {
                finalEvents[i] = _.sortBy(stageEvents, ['start', 'end']);
            });
            c.events = finalEvents;
            return c;
        }), map(function (c) { return new AppConfig().deserialize(c); }), tap(function (c) {
            _.each(c.events, function (event) {
                _this._fixStartEnd(event);
            });
        }))
            .subscribe(function (config) { return _this._configSource.next(config); });
    };
    ConfigurationService.prototype._fixStartEnd = function (event) {
        var _this = this;
        if (event.events) {
            _.each(event.events, function (e) {
                _this._fixStartEnd(e);
                if (event.start > e.start) {
                    event.start = e.start;
                }
                if (event.end < e.end) {
                    event.end = e.end;
                }
            });
        }
    };
    /**
     * Trie la liste des événements par date de début/fin
     * @param events Liste des événements
     */
    ConfigurationService.prototype._sortEvents = function (events) {
        var _this = this;
        events = _.sortBy(events, ['start', 'end']);
        _.each(events, function (event) {
            if (event.events) {
                event.events = _this._sortEvents(event.events);
            }
        });
        return events;
    };
    ConfigurationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigurationService_Factory() { return new ConfigurationService(i0.ɵɵinject(i1.HttpClient)); }, token: ConfigurationService, providedIn: "root" });
    return ConfigurationService;
}());
export { ConfigurationService };
