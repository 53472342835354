import * as d3 from 'd3';
import * as _ from 'lodash';
import * as $ from 'jquery';

import { Component, Input, AfterViewInit, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Sheet, DataEvent } from 'src/app/entities';
import { UtilsService, SheetsService } from 'src/app/shared/services';
import { SelectItem } from 'primeng/api/selectitem';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sheet-tree',
  templateUrl: './sheet-tree.component.html'
})
export class SheetTreeComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() events: DataEvent[][];
  @Input() baseEventId: number;

  /**
   * Liste des types d'événements pour les filtres
   */
  public types: SelectItem[] = [];

  /**
   * Liste des types d'événements sélectionnés dans le filtre
   */
  public selectedTypes: string[] = [];

  /**
   * Envoyé quand on souhaite afficher un événement
   */
  @Output() onDisplayEvent = new EventEmitter<number>();

  /**
   * Taille d'affichage du texte (en px)
   */
  private _fontSize = 14;

  /**
   * Hauteur de ligne du texte (multiplicateur de la font size)
   */
  private _lineHeight = 1.3;

  /**
   * Marge interne des nodes pour le placement du texte
   */
  private _nodePadding = this._fontSize * 0.36;

  /**
   * Taille standard d'un node (le height sera surchargé en fonction du texte)
   */
  private _nodeSize = {
    width: this._fontSize * 12.15 + (this._nodePadding * 2),
    height: (this._fontSize * this._lineHeight) + (this._nodePadding * 2)
  };

  /**
   * Marge minimale entre les nodes
   */
  private _nodeMargin = {
    x: 10,
    y: 60
  };

  /**
   * Liste des nodes pour l'affichage arborescence
   */
  private _treeMap;

  /**
   * Données générant l'arborescence
   */
  private _root;

  /**
   * Listener de déplacement et zoom du svg
   */
  private _zoomListener;

  /**
   * Compteur de ligne par étage de l'arborescence
   */
  private _textLinesByStage: number[] = [];

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs = new Subscription();

  constructor(
    private _utils: UtilsService,
    private _sheetService: SheetsService
  ) { }

  ngOnInit() {
    this._subs.add(this._sheetService.filterTypes$.subscribe(types => this._filterEvents(types)));

    let types = this._sheetService.getEventTypeList(this.events);
    types = _.sortBy(types, t => this._utils.removeAccents(t));
    this.selectedTypes = types;
    this.types = [];
    _.each(types, type => {
      this.types.push({ label: type, value: type });
    });
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => this._generateTree(this._generateDataTree()), 500);
  }

  /**
   * Filtre les événements affichés en fonction des types sélectionnés
   */
  public filterEventsByType() {
    this._sheetService.filterEventsByTypes(this.selectedTypes);
  }

  /**
   * Effectue le filtrage des événements
   * @param types Types sur lesquels filtrer
   */
  private _filterEvents(types: string[]) {
    this.selectedTypes = types;
    let svg = d3.select("svg");
    svg.selectAll(".arbo-link")
      .attr("opacity", d => types.indexOf(d.data.type) < 0 ? 0.2 : 1);
    svg.selectAll(".arbo-node")
      .attr("opacity", d => types.indexOf(d.data.type) < 0 ? 0.2 : 1)
      .attr("cursor", d => types.indexOf(d.data.type) < 0 ? "default" : "pointer");
  }

  /**
   * Génère l'arborescence de données pour d3js
   */
  private _generateDataTree(): any {
    let flatEvents: DataEvent[] = _.flatten(this.events);

    let topEvent: DataEvent = _.find(flatEvents, { parentId: 0 });

    let data: any = null;

    if (topEvent) {
      data = {
        id: topEvent.id,
        name: this._setTextToMultiline(topEvent.title),
        type: topEvent.type
      }
      let events = [topEvent];
      this._textLinesByStage.push(data.name.length);
      let currentDataList = [data];
      let stage = 1;
      do {
        let foundChilds: DataEvent[] = [];
        let dataList: any[] = [];
        this._textLinesByStage.push(0);
        _.each(events, (event: DataEvent, i: number) => {
          let eventChilds = _.filter(flatEvents, { parentId: event.id });
          if (!currentDataList[i].children && eventChilds.length > 0) {
            currentDataList[i].children = [];
          }
          _.each(eventChilds, (c: DataEvent) => {
            let data = {
              id: c.id,
              name: this._setTextToMultiline(c.title),
              type: c.type
            };
            currentDataList[i].children.push(data);
            dataList.push(data);
            if (this._textLinesByStage[stage] < data.name.length) {
              this._textLinesByStage[stage] = data.name.length;
            }
          });
          foundChilds = foundChilds.concat(eventChilds);
        });

        currentDataList = dataList;
        events = foundChilds;
        stage++;
      } while (events.length > 0);
    }
    return data;
  }

  /**
   * Génère l'arborescence SVG des événements
   * @param eventTree Données des événements pour l'arborescence
   */
  private _generateTree(eventTree) {
    this._root = d3.hierarchy(eventTree, d => d.children);

    this._root.dx = this._nodeSize.width + (this._nodeMargin.x * 2);
    this._root.dy = this._nodeSize.height + (this._nodeMargin.y * 2);

    this._treeMap = d3.tree().nodeSize([this._root.dx, this._root.dy]);

    const svg = d3.select("#d3-container svg")
      .attr('width', "100%")
      .attr('height', "100%")
      .attr("viewBox", [0, 0, $('#d3-container').width(), $('#d3-container').height()]);

    const g = svg.append("g")
      .attr("font-family", "Arial,sans-serif")
      .attr("font-size", this._fontSize);

    // Bind des événements de zoom et translation
    this._zoomListener = d3.zoom()
      .scaleExtent([0.1, 3])
      .on("zoom", () => g.attr("transform", () => d3.event.transform));

    svg.call(this._zoomListener);

    this._updateTree();
  }

  /**
   * Dessine le lien entre deux nodes
   * @param d Informations des deux nodes
   */
  private _drawLink(d) {
    let startPoint = [d.parent.x, d.parent.y];
    let endPoint = [d.x, d.y - this._getNodeHeight(d)];

    let q1Point = [startPoint[0], (startPoint[1] + endPoint[1]) / 2];
    let q2Point = [endPoint[0], (startPoint[1] + endPoint[1]) / 2];

    let path = 'M' + startPoint[0] + ' ' + startPoint[1];
    path += 'C' + q1Point[0] + ' ' + q1Point[1] + ',';
    path += q2Point[0] + ' ' + q2Point[1] + ',';
    path += endPoint[0] + ' ' + endPoint[1];

    return path;
  }

  /**
   * Génère un tableau multiligne du texte en fonction de sa taille et de la largeur d'une node
   * @param text Texte à transformer
   */
  private _setTextToMultiline(text: string): string[] {
    let textWidth = this._utils.measureText(text, this._fontSize);

    if (textWidth <= (this._nodeSize.width - (this._nodePadding * 2))) {
      return [text];
    }

    let currentLine = "";
    let split = text.split(' ');
    let lines: string[] = [];

    for (let i = 0; i < split.length; i++) {
      if (!currentLine || this._utils.measureText(currentLine + ' ' + split[i], this._fontSize) <= (this._nodeSize.width - (this._nodePadding * 2))) {
        if (currentLine) {
          currentLine += ' ';
        }
        currentLine += split[i];
      } else {
        lines.push(currentLine);
        currentLine = split[i];
      }
    }

    lines.push(currentLine);

    return lines;
  }

  /**
   * Renvoie la hauteur de la node en fonction de sa quantité de texte
   * @param node Node
   */
  private _getNodeHeight(node) {
    return (this._textLinesByStage[node.depth] * this._fontSize * this._lineHeight) + (this._nodePadding * 2)
  }

  /**
   * Lance le repli/dépli d'une node
   * @param d Node
   */
  private _toggleNodeChildren(d) {
    if (d.children) {
      d._children = d.children;
      d.children = null;
    } else if (d._children) {
      d.children = d._children;
      d._children = null;
    }

    this._updateTree(d);
  }

  /**
   * Demande de voir l'événement
   * @param d Node
   */
  private _goToEvent(d) {
    if(this.selectedTypes.indexOf(d.data.type) >= 0) {
      this.onDisplayEvent.emit(d.data.id);
    }
  }

  /**
   * Met à jour l'arborescence avec animations
   * @param source Node repliée/dépliée
   */
  private _updateTree(source?: any) {
    // Définition x et y
    let treeData = this._treeMap(this._root);

    let duration = 750;
    let nodes = treeData.descendants();
    let links = treeData.descendants().slice(1);

    if (!source) {
      source = _.find(nodes, n => n.data.id === this.baseEventId);
      source.x0 = $('#d3-container').width() / 2;
      source.y0 = 0;
    }

    // customs de taille par rapport aux tailles de texte
    let diffs = [];
    _.each(this._textLinesByStage, (stageLinesNumber, i) => {
      let realheight = (stageLinesNumber * this._fontSize * this._lineHeight) + (this._nodePadding * 2);
      let diff = realheight - this._nodeSize.height;

      if (i > 0) {
        diff += diffs[i - 1];
      }

      diffs.push(diff);
    });

    _.each(nodes, node => {
      node.y += diffs[node.depth];
    });

    // ======= MAJ des nodes =======
    const arboNodes = d3.select("#d3-container svg g")
      .selectAll("g.arbo-node")
      .data(nodes, d => d.data.id);

    // Placement des nodes entrantes (nouvelles données/uncollapse les enfants)
    const arboNodesEnter = arboNodes.enter().append("g")
      .attr("class", "arbo-node")
      .attr("opacity", 0)
      .attr("transform", d => `translate(${source.x0},${source.y0}) scale(0)`);

    // Dessin de la node
    const nodeSquare = arboNodesEnter.append("g")
      .attr("class", "arbo-node-square")
      .on('click', d => this._goToEvent(d));

    nodeSquare.append("rect")
      .attr("fill", "#005a74")
      .attr("width", this._nodeSize.width)
      .attr("height", d => this._getNodeHeight(d));

    nodeSquare.append("text")
      .attr("dy", 0)
      .attr("x", 0)
      .attr("fill", "white")
      .attr("text-anchor", "middle")
      .selectAll("tspan")
      .data(d => {
        let names = [];
        _.each(d.data.name, namePart => {
          names.push({
            depth: d.depth,
            name: namePart
          });
        });
        return names;
      })
      .enter()
      .append("tspan")
      .attr("dy", (d, i, datas) => {
        if (i === 0) {
          let textheight = datas.length * this._fontSize * this._lineHeight;
          return ((this._getNodeHeight(d) - textheight) / 2) + this._fontSize;
        }
        return (this._fontSize * this._lineHeight);
      })
      .attr("x", (this._nodeSize.width / 2))
      .text(d => d.name);

    // Bouton de collapse de la node
    const collapseBtn = arboNodesEnter.filter(d => d.children || d._children).append("g")
      .attr("class", "arbo-node-collapse-btn")
      .attr("transform", d => `translate(${(this._nodeSize.width / 2)},${(this._getNodeHeight(d) + 8)})`)
      .on('click', d => this._toggleNodeChildren(d));

    collapseBtn.append("circle")
      .attr('r', 11)
      .attr("fill", "#dc3545");

    collapseBtn.append("text")
      .attr("x", 0)
      .attr("fill", "white")
      .attr("font-size", "1.2em")
      .attr("text-anchor", "middle");

    // Ajout des nodes entrantes dans les nodes actuelles
    let arboNodesUpdate = arboNodesEnter.merge(arboNodes);

    arboNodesUpdate.selectAll(".arbo-node-collapse-btn text")
      .attr("dy", d => d.children ? 4 : 6)
      .text(d => d.children ? "-" : "+");

    // Mise à jour animée des nodes entrantes et actuelles
    arboNodesUpdate
      .transition()
      .duration(duration)
      .attr("opacity", d => this.selectedTypes.indexOf(d.data.type) < 0 ? 0.2 : 1)
      .attr("cursor", d => this.selectedTypes.indexOf(d.data.type) < 0 ? "default" : "pointer")
      .attr("transform", d => `translate(${d.x - (this._nodeSize.width / 2)},${d.y - this._getNodeHeight(d)}) scale(1)`);


    // Animation puis suppression des nodes sortantes (collapsed)
    arboNodes.exit()
      .transition()
      .duration(duration)
      .attr("opacity", 0)
      .attr("transform", d => `translate(${source.x},${source.y}) scale(0)`)
      .remove();

    // ======= MAJ des liens =======
    const nodesLinks = d3.select("#d3-container svg g")
      .selectAll("path.arbo-link")
      .data(links, d => d.data.id);

    // Placement des liens des nodes entrantes
    let nodesLinksEnter = nodesLinks.enter().insert('path', 'g')
      .attr("class", "arbo-link")
      .attr("fill", "none")
      .attr("stroke", "#888")
      .attr("stroke-opacity", 1)
      .attr("stroke-width", 1.5)
      .attr("d", d => this._drawLink({
        x: source.x0,
        y: source.y0 + this._getNodeHeight(d),
        depth: d.depth,
        parent: { x: source.x0, y: source.y0 }
      }))
      .attr("opacity", 0);

    // Ajout des liens des nodes entrantes aux liens actuels, puis animation
    nodesLinksEnter.merge(nodesLinks)
      .transition()
      .duration(duration)
      .attr("d", d => this._drawLink(d))
      .attr("opacity", d => this.selectedTypes.indexOf(d.data.type) < 0 ? 0.2 : 1);

    // Animation puis suppression des liens des nodes sortantes
    nodesLinks.exit()
      .transition()
      .duration(duration)
      .attr("d", d => this._drawLink({
        x: source.x,
        y: source.y + this._getNodeHeight(d),
        depth: d.depth,
        parent: { x: source.x, y: source.y }
      }))
      .attr("opacity", 0)
      .remove();

    // Mise à jour des positions des nodes
    _.each(nodes, node => {
      node.x0 = node.x;
      node.y0 = node.y;
    });

    // Déplacement vers la node repliée/dépliée
    let g = d3.select("#d3-container svg g");
    let transform = d3.zoomTransform(g.node());
    transform.x = (-source.x * transform.k) + $('#d3-container').width() / 2;
    transform.y = (-source.y * transform.k) + $('#d3-container').height() / 2;

    g.transition()
      .duration(duration)
      .attr("transform", transform);

    setTimeout(() => d3.select("#d3-container svg").call(this._zoomListener.transform, transform), duration);
  }

}
