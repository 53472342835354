import * as tslib_1 from "tslib";
import { EntityMapper } from './entity-mapper.model';
/**
 * Période de fond de la frise
 */
var SectionPeriod = /** @class */ (function (_super) {
    tslib_1.__extends(SectionPeriod, _super);
    function SectionPeriod() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * Identifiant de la période
         */
        _this.id = null;
        /**
         * Nom de la période
         */
        _this.title = "";
        /**
         * Début de la période (en Ma)
         */
        _this.start = null;
        /**
         * Fin de la période (en Ma)
         */
        _this.end = null;
        /**
         * Couleur de la période (tiré de http://infoterre.brgm-rec.fr/fiche-registry/thesaurus.php?ncl=chrono)
         */
        _this.color = "#666666";
        /**
         * Ère de la période (en anglais)
         */
        _this.era = "";
        /**
         * Style inline de la période
         */
        _this.style = {};
        /**
         * Dégradé de couleur pour la limite
         */
        _this.gradient = "";
        return _this;
    }
    return SectionPeriod;
}(EntityMapper));
export { SectionPeriod };
