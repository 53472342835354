import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Input, ViewChildren, QueryList, EventEmitter, Output, OnChanges } from '@angular/core';
import { SheetComment, User } from 'src/app/entities';
import { AccountService } from 'src/app/shared/services';
import { SelectItem } from 'primeng/api/selectitem';
import { Subscription } from 'rxjs';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'sheet-comments',
  templateUrl: './sheet-comments.component.html'
})
export class SheetCommentsComponent implements OnInit, OnDestroy, OnChanges {

  /**
   * Liste des commentaires
   */
  @Input() comments: SheetComment[];

  /**
   * Commentaire à modifier
   */
  public commentToUpdate: SheetComment;

  /**
   * Liste filtrée des commentaires
   */
  public filteredComments: SheetComment[] = [];

  /**
   * Liste des IDs des événements sélectionnés dans le filtre
   */
  public selectedEventsIds: number[] = [];

  /**
   * Liste des événements pour le filtre
   */
  public eventsList: SelectItem[] = [];

  /**
   * Utilisateur actuel de l'application, si connecté
   */
  public currentUser: User;
  
  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs = new Subscription();

  /**
   * Événement lancé à la demande d'enregistrement d'un commentaire
   */
  @Output() onUpdateComment = new EventEmitter<SheetComment>();

  /**
   * Liste des popovers de formulaire d'ajout de commentaire
   */
  @ViewChildren('commentPop') commentPopovers: QueryList<NgbPopover>;

  constructor(
    private _accountService: AccountService
    ) { }

  ngOnInit() {
    this._subs.add(this._accountService.currentUser$.subscribe(user => this.currentUser = user));

    this.getEventIdsByComments();
    this.filterEventsByEvent();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  ngOnChanges(changes) {
    if (changes.comments) {
      this.getEventIdsByComments();
      this.filterEventsByEvent();
    }
  }

  /**
   * Affiche le formulaire d'ajout de commentaire
   * @param popover Référence de l'objet popover contenant le formulaire
   * @param comment Le commentaire à modifier
   */
  public displayCommentForm(popover: NgbPopover, comment: SheetComment) {
    if(!this.currentUser) {
      return;
    }
     if (comment && this.commentToUpdate && this.commentToUpdate.id === comment.id) {
      this.closeCommentForm(popover);
    } else {
      this.closeCommentForm();
      this.commentToUpdate = _.cloneDeep(comment);
      delete this.commentToUpdate.event;
      popover.open();
    }
  }

  /**
   * Ferme les formulaires de commentaire
   * @param popover Référence de la popover contenant le formulaire. Si fourni, lui seul est fermé
   */
  public closeCommentForm(popover?: NgbPopover) {
    if (popover) {
      popover.close();
    } else if (this.commentPopovers) {
      _.each(this.commentPopovers.toArray(), (p: NgbPopover) => {
        p.close();
      });
    }
    this.commentToUpdate = null;
  }

  /**
   * Formate le commentaire et demande son enregistrement au parent
   * @param commentForm Référence du formulaire
   */
  public saveComment(commentForm: NgForm) {
    if (this.currentUser && commentForm.valid) {
      this.commentToUpdate.date = new Date();
      this.onUpdateComment.emit(_.cloneDeep(this.commentToUpdate));
      this.closeCommentForm();
      this.commentToUpdate = null;
    }
  }

  /**
   * Filtre la liste des commentaires en fonction des événements sélectionnés
   */
  public filterEventsByEvent() {
    this.filteredComments = _.filter(this.comments, d => this.selectedEventsIds.indexOf(d.eventId) >= 0);
  }

  /**
   * Récupère la liste des ids des evenements pour l'affichage des commentaires
   */
  public getEventIdsByComments() {
    _.each(this.comments, (comment: SheetComment) => {
      if (this.selectedEventsIds.indexOf(comment.eventId) < 0) {
        this.eventsList.push({ label: comment.event.title, value: comment.eventId });
        this.selectedEventsIds.push(comment.eventId);
      }
    });
  }

}
