import { Injectable } from '@angular/core';
import { User } from 'src/app/entities';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Uris } from '../constants/uris';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  /**
   * Source pour un unique utilisateur
   */
  private _currentUserSource = new BehaviorSubject<User>(null);

  /**
   * Lancé à la récupération d'un unique utilisateur
   */
  public currentUser$ = this._currentUserSource.asObservable();

  constructor(
    private _toastr: ToastrService,
    private _http: HttpClient
  ) { }

  /**
   * Vérifie si l'utilisateur est déjà connecté et applique sa connexion si c'est le cas
   */
  public isConnected() {
    this._http.get<User>(`${Uris.USER_URL}isConnected`).subscribe((user) => {
      if (user) {
        this._currentUserSource.next(new User().deserialize(user));
      } else {
        this.login();
      }
    }, (error) => {
      if (error && error.status === 401) {
        this.login();
      }
    }  
    );
  }

  /**
   * Connecte un utilisateur
   */
  public login() {
    window.location.href = `${window.location.origin}/back/`;
  }

  /**
   * Déconnecte un utilisateur
   */
  public logout() {
    window.location.href = `${window.location.origin}${Uris.USER_URL}logout`;
  }
}