<div id="sheet-documents" class="d-flex flex-column h-100">
  <div class="sheet-tab-title d-flex align-items-center">
    <h2>Documents</h2>
    <div class="type-filter-container d-flex align-items-center ml-auto" *ngIf="eventsList.length > 0">
      <label for="sheet-documents-filter" class="mb-1 mr-2">Filtrer par type :</label>
      <div class="type-filter">
        <p-multiSelect [options]="eventsList" [(ngModel)]="selectedEventsIds" inputId="sheet-documents-filter"
          styleClass="w-100" class="w-100" [filter]="false" defaultLabel="Aucun événement sélectionné"
          selectedItemsLabel="{0} événements sélectionnés" [maxSelectedLabels]="2" (onChange)="filterEventsByEvent()">
        </p-multiSelect>
      </div>
    </div>
  </div>
  <div class="gallery sheet-tab-content">
    <div class="row no-gutters">
      <div class="col-3 item-container" *ngFor="let doc of filteredDocuments">
        <a class="d-block h-100" (click)="focusDocument(doc)">
          <figure class="w-100 h-100 d-flex align-items-center justify-content-center">
            <ng-container [ngSwitch]="doc.type">
              <img *ngSwitchCase="'image'" [src]="doc.url" alt="">
              <div *ngSwitchDefault class="icon-container">
                <i class="fa" [ngClass]="{
                  'fa-file-word': doc.type === 'doc', 
                  'fa-file-excel': doc.type === 'xls', 
                  'fa-file-pdf': doc.type === 'pdf'
                }"></i>
              </div>
            </ng-container>
            <figcaption>
              {{doc.title}}
              <i class="d-block">{{doc.event.title}}</i>
            </figcaption>
          </figure>
        </a>
      </div>
    </div>
  </div>
  <div class="lightbox-background d-flex align-items-center justify-content-center" (click)="closeLightbox()" *ngIf="focusedDocument">
    <div class="lightbox">
      <div class="d-flex align-items-center">
        <h3 class="lightbox-title">{{focusedDocument.title}}</h3>
        <button type="button" class="btn btn-close ml-auto" (click)="closeLightbox()">&times;</button>
      </div>
      <ng-container [ngSwitch]="focusedDocument.type">
        <img *ngSwitchCase="'image'" [src]="focusedDocument.url" alt="">
        <div *ngSwitchCase="'pdf'">
          <iframe [src]="focusedDocument.url | sanitizeUrl" frameborder="0"></iframe>
        </div>
      </ng-container>
    </div>
  </div>
</div>