import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConfig, User } from 'src/app/entities';
import { ConfigurationService, AccountService } from 'src/app/shared/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  /**
   * Titre de la page
   */
  public title: string;

  /**
   * Sous-titre de la page
   */
  public subtitle: string;

  /**
   * Utilisateur actuel
   */
  public currentUser: User;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs = new Subscription();

  constructor(
    private _configService: ConfigurationService,
    private _accountService: AccountService
  ) { }

  ngOnInit() {
    this._subs.add(this._configService.config$.subscribe(config => this._updateTitles(config)));
    this._subs.add(this._accountService.currentUser$.subscribe(user => this.currentUser = user));

    this._accountService.isConnected();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Demande la connexion d'un utilisateur
   */
  public login() {
    this._accountService.login();
  }

  /**
   * Demande la déconnexion de l'utilisateur actuel
   */
  public logout() {
    this._accountService.logout();
  }

  /**
   * Met à jour les titres à réception de la configuration
   * @param config Configuration reçue
   */
  private _updateTitles(config: AppConfig) {
    this.title = config.title;
    this.subtitle = config.subtitle;
  }

}
