import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UtilsService = /** @class */ (function () {
    function UtilsService(_http) {
        this._http = _http;
        /**
         * Source de demande de téléchargement
         */
        this._downloadSource = new Subject();
        /**
         * Lancé à chaque demande de téléchargement
         */
        this.download$ = this._downloadSource.asObservable();
    }
    //https://stackoverflow.com/questions/4726344/how-do-i-change-text-color-determined-by-the-background-color
    /**
     * Renvoie une couleur de texte noire ou blanche en fonction de la couleur de fond
     * @param bgColor Couleur de fond, en hexadécimal (ex: '#fa12e4')
     */
    UtilsService.prototype.idealTextColor = function (bgColor) {
        var nThreshold = 105;
        var components = this._getRGBComponents(bgColor);
        var bgDelta = (components.R * 0.299) + (components.G * 0.587) + (components.B * 0.114);
        return ((255 - bgDelta) < nThreshold) ? "#000000" : "#ffffff";
    };
    /**
     * Renvoie une version RGB d'une couleur hexadécimale
     * @param color Couleur en hexadécimal
     */
    UtilsService.prototype._getRGBComponents = function (color) {
        var r = color.substring(1, 3);
        var g = color.substring(3, 5);
        var b = color.substring(5, 7);
        return {
            R: parseInt(r, 16),
            G: parseInt(g, 16),
            B: parseInt(b, 16)
        };
    };
    /**
     * Remplace les lettres accentuées par leur équivalent non accentuée
     * @param text - Texte à traiter
     */
    UtilsService.prototype.removeAccents = function (text) {
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    };
    /**
     * Demande le téléchargement d'un fichier
     * @param url URL du fichier
     */
    UtilsService.prototype.downloadFile = function (url) {
        this._downloadSource.next(url);
    };
    /**
     * Mesure la taille du texte donné
     * @param text Texte à mesurer
     */
    UtilsService.prototype.measureText = function (text, fontSize) {
        var c = document.createElement("canvas");
        var ctx = c.getContext("2d");
        ctx.font = fontSize + "px Arial";
        return ctx.measureText(text).width;
    };
    UtilsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilsService_Factory() { return new UtilsService(i0.ɵɵinject(i1.HttpClient)); }, token: UtilsService, providedIn: "root" });
    return UtilsService;
}());
export { UtilsService };
