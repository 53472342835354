<nav role="navigation" id="timeline-map">
  <div class="position-marker"></div>
  <ul class="map-list row no-gutter">
    <li #periodSection class="col" *ngFor="let period of periods">
      <a [ngStyle]="{background: period.style.background, color: period.style.color}" i18n-title
        title="Aller à cette période" (click)="goToPeriod(period)">
        {{period.title}}
      </a>
    </li>
  </ul>
</nav>
<nav id="timeline-arrows">
  <ul>
    <li>
      <a data-goto="previous" class="arrow arrow-left" title="Période précédente"
        (click)="goToNearbyPeriod('previous')">
        <i class="fa fa-chevron-left"></i>
      </a>
    </li>
    <li>
      <a data-goto="next" class="arrow arrow-right" title="Période suivante" (click)="goToNearbyPeriod('next')">
        <i class="fa fa-chevron-right"></i>
      </a>
    </li>
  </ul>
</nav>