import * as tslib_1 from "tslib";
import { EntityMapper } from './entity-mapper.model';
/**
 * Utilisateur de l'application
 */
var User = /** @class */ (function (_super) {
    tslib_1.__extends(User, _super);
    function User() {
        var _this = _super.call(this) || this;
        /**
         * Email
         */
        _this.email = "";
        /**
         * Nom d'usage
         */
        _this.username = "";
        _this._mapperDefs = [{ front: 'username', back: 'email' }];
        return _this;
    }
    return User;
}(EntityMapper));
export { User };
