import * as tslib_1 from "tslib";
import { EntityMapper } from './entity-mapper.model';
/**
 * Référence bibliographique
 */
var SheetBibliography = /** @class */ (function (_super) {
    tslib_1.__extends(SheetBibliography, _super);
    function SheetBibliography() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * Url vers la référence bibliographique
         */
        _this.url = "";
        /**
         * Nom
         */
        _this.title = "";
        /**
         * Description
         */
        _this.description = "";
        /**
         * ID de l'événement concerné
         */
        _this.eventId = null;
        /**
         * Événement concerné
         */
        _this.event = null;
        return _this;
    }
    return SheetBibliography;
}(EntityMapper));
export { SheetBibliography };
